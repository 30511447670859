<template>
  <iframe
    :src="preparedUrl"
    width="100%"
    height="100%"
    frameborder="0"
    allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
  />
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      default: "",
    },
  },
  computed: {
    preparedUrl() {
      let facecastUrl = (this.url || "").trim();
      if (facecastUrl) {
        if (window.location.href.includes("https")) {
          if (!facecastUrl.includes("https") && facecastUrl.includes("http")) {
            facecastUrl = facecastUrl.replace(/http/, "https");
          }
        }
      }
      return facecastUrl;
    },
  },
};
</script>
