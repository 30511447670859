<template>
  <video id="player" ref="video" class="video-js" playsinline></video>
</template>

<script>
import "videojs-contrib-hls";
import { mapGetters, mapState } from "vuex";
import { setPlayEvents } from "@/utils";
import { broadcast, room } from "@/store/modules/store.namespaces";
import config from "@/settings/config";
import videojsMixin from "@/mixins/players/videojs.mixin";
import { BROADCAST_URL } from "@/store/modules/broadcast/getter-types";

export default {
  mixins: [videojsMixin],
  props: {
    providedUrl: {
      type: String,
      default: "",
    },
    isTypicalType: {
      type: Boolean,
      default: false,
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      player: null,
    };
  },
  computed: {
    ...mapState(room, {
      room: "roomNumber",
    }),
    ...mapGetters(broadcast, {
      broadcastUrl: BROADCAST_URL,
    }),
  },
  async mounted() {
    await this.getVideoJs();
    const options = {
      controls: true,
      autoplay: this.autoplay,
      preload: "auto",
      width: 320,
      height: 180,
      aspectRatio: "16:9",
      fluid: true,
      sources: [
        {
          src: await this.getHlsUrl(),
          type: this.broadcastUrl ? "video/mp4" : "application/x-mpegURL",
        },
      ],
    };
    this.player = this.videojs(this.$refs.video, options);
    this.player.userActive(false);
    this.player.on("play", () => {
      this.$emit("play");
    });
    this.player.on("pause", () => {
      this.$emit("pause");
    });
    this.player.on("seeked", () => {
      if (!this.isTypicalType) {
        this.$emit("seeked");
      }
    });
    setPlayEvents();
  },
  beforeDestroy() {
    this.videojs(this.$refs.video).dispose();
  },
  methods:  {
    async getHlsUrl() {
      return this.providedUrl || this.broadcastUrl || await config.fallbackHlsUrl(this.room);
    },
  }
};
</script>
