<template>
  <div class="screen-saver">
    <img class="screen-saver__img" :src="imagePath" />
    <span v-show="time" class="screen-saver__time">
      {{ time }}
    </span>
  </div>
</template>

<script>
import EasyTimer from "easytimer";
import { SCREEN_SAVER_TIMEOUT } from "@/constants/broadcast/broadcast-const";
import { makeUrl } from "@/utils/index";

export default {
  name: "BroadcastScreenSaver",
  props: {
    imageSrc: {
      type: String,
      default: "",
      required: true,
    },
    disableTimer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      timer: null,
      time: "",
    };
  },
  computed: {
    // Метод генерации ссылки на изображение
    imagePath() {
      return makeUrl(this.imageSrc);
    },
  },
  created() {
    if (!this.disableTimer) {
      this.timer = new EasyTimer();
      this.timer.addEventListener("secondsUpdated", this.secondsUpdatedHandler);
      this.timer.addEventListener("targetAchieved", this.targetAchievedHandler);
    }
  },
  mounted() {
    if (!this.disableTimer) {
      this.timer.start({
        countdown: true,
        startValues: {
          seconds: SCREEN_SAVER_TIMEOUT / 1000,
        },
      });
    }
  },
  beforeDestroy() {
    if (!this.disableTimer) {
      this.timer.removeEventListener("secondsUpdated", this.secondsUpdatedHandler);
      this.timer.removeEventListener("targetAchieved", this.targetAchievedHandler);
      this.timer = null;
    }
  },
  methods: {
    secondsUpdatedHandler() {
      this.time = this.timer.getTimeValues().toString();
    },
    targetAchievedHandler() {
      this.$emit("screen-saver-hide");
    },
  },
};
</script>

<style lang="less" scoped>
.screen-saver {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 40;
  background-color: #000;

  &__img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__time {
    position: absolute;
    bottom: 16px;
    left: 50%;
    display: flex;
    align-items: center;
    min-height: 25px;
    padding: 5px;
    font-size: 21px;
    color: #000;
    background-color: #fff;
    border-radius: 4px;
    transform: translateX(-50%);
  }
}
</style>
