<template>
  <ws-icon-select
    data-testid="qualitySelect"
    :class="$style.select"
    :options="qualities"
    :value="selectedQuality"
    :selectable="selectable"
    :append-to-body="false"
    :get-option-key="getOptionKey"
    label="name"
    @input="selectQuality"
    @search:focus="opened = true"
    @search:blur="opened = false"
  >
    <ws-icon data-testid="quality-button-icon" xxl light color="white">
      {{ opened ? "times" : "cog" }}
    </ws-icon>
  </ws-icon-select>
</template>

<script>
import { BITRATE_BY_RESOLUTION, RESOLUTIONS } from "@/constants/broadcast/broadcast-const";
import WsIcon from "@/components/base/WsIcon";
import WsIconSelect from "@/components/base/WsIconSelect";

/**
 * Компонент выбора качества просматриваемой трансляции
 *
 * @vue-prop {object} selectedQuality - выбраное качество
 * @vue-prop {object} maxQuality - максимальное значение определяемое входящими параметрами стрима
 * @vue-data {boolean} opened - открыт/закрыт список
 * @vue-computed {Array} qualities - подготовленный список, исходя из максимально доступного значения
 * @vue-event {object} change - Событие при выборе нового качества
 * @example <broadcast-quality-select v-model="quality" :max-quality="{width: 640}">
 */
export default {
  name: "BroadcastQualitySelect",
  components: {
    WsIcon,
    WsIconSelect,
  },
  model: {
    prop: "selectedQuality",
    event: "change",
  },
  props: {
    selectedQuality: {
      type: Object,
      required: true,
    },
    maxQuality: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      opened: false,
    };
  },
  computed: {
    qualities() {
      return Object.values(RESOLUTIONS).map(resolution => {
        const localResolution = { ...resolution };
        localResolution.bitrate = BITRATE_BY_RESOLUTION[localResolution.name].minBitrate;
        return localResolution;
      });
    },
  },
  methods: {
    /**
     * Получаем ключ опции по которому определяем какое значения опции выбрано
     *
     * @param {object} option - опция качества
     * @returns {string} - ключ для опции
     */
    getOptionKey(option) {
      return option.name;
    },
    /**
     * Определяем доступна ли опция для выбора
     *
     * @param {object} option - опция качества
     * @returns {boolean} - можно выбрать опцию или нет
     */
    selectable(option) {
      return option.width <= this.maxQuality.width;
    },
    /**
     * Обработка события выбора качества из селекта и прокидывание значение в родительский компонент
     *
     * @param {object} quality - выбранное качестов
     * @function
     */
    selectQuality(quality) {
      /**
       * Обновление значения v-model
       *
       * @event change
       * @type {object}
       * @property {object} выбраное качество
       */
      this.$emit("change", quality);
    },
  },
};
</script>

<style lang="scss" module>
.select :global(.vs__dropdown-menu) {
  top: calc(100% + 5px);

  &::before {
    position: absolute;
    top: -10px;
    left: 10px;
    content: "";
    border: 3px solid $white;
  }
}

.select :global(.vs__dropdown-option) > * {
  min-height: 25px;
}

.select :global(.vs__selected-options) > * {
  width: 42px;
  height: 42px;
}
</style>
