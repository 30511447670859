<template>
  <div
    v-fullscreen="'broadcast'"
    class="player-wrapper"
    :class="{
      'player-wrapper--viewer': isViewer,
      'player-wrapper--fullscreen': inFullscreenMode && !$fullscreen.isEnabled,
      'player-wrapper--transparent': $isCordovaIOS && !speakerVideoMuted && stream && !isSpeaker,
      'player-wrapper--negative-index': hasVideoNegativeIndex,
    }"
    @mouseenter="handlePlayerMouseEnter"
    @mouseleave="handlePlayerMouseLeave"
    @mousemove="handlePlayerMouseMove"
  >
    <div
      class="player-wrapper__main-player"
      :class="{
        'player-wrapper__main-player--speaker': $isCordovaIOS && isSpeaker,
      }"
    >
      <div
        id="broadcastPlayer"
        ref="localPlayer"
        class="player-wrapper__local-player"
        :class="{
          'player-wrapper__local-player--error': hasError,
        }"
      />
      <div
        id="broadcastCapturePlayer"
        ref="capturePlayer"
        class="player-wrapper__local-player player-wrapper__capture-player"
        :class="{
          'player-wrapper__capture-player--shown': streamCapture,
        }"
      />
      <div v-if="videoMuted && stream && !paused" class="player-wrapper__avatar">
        <img
          v-if="userAvatar"
          class="player-wrapper__avatar-img"
          :src="userAvatar"
          alt="Speaker avatar"
        />
        <ws-icon v-else class="player-wrapper__avatar-stub" regular>
          user
        </ws-icon>
      </div>
      <broadcast-web-rtc-player-controls
        v-if="!hasError"
        ref="playerControls"
        class="player-wrapper__controls"
        :type="type"
        :strategy="strategy"
        :media-provider="mediaProvider"
        :video-muted="videoMuted"
        :audio-muted="audioMuted"
        :volume-muted="audioMuted"
        :fullscreen="inFullscreenMode"
        :played="stream && !pending && !paused"
        :screen-share-allowed="screenShareAllowed"
        :screen-share-run="screenShareRun"
        :volume="volume"
        @play="play"
        @audio="toggleAudio"
        @video="toggleVideo"
        @volume="toggleVolume"
        @full-screen="fullscreen"
        @screen-share="handleScreenShare"
        @change-volume="changeVolume"
      >
        <template #additionalControls="{ show }">
          <broadcast-quality-select
            v-if="isViewer"
            v-show="show"
            v-model="selectedQuality"
            :max-quality="resolution"
            class="player-wrapper__quality"
          />
        </template>
      </broadcast-web-rtc-player-controls>
      <div v-if="hasError" class="player-wrapper__error-message">
        <p class="player-wrapper__error-message-text">
          {{ $t("broadcast.errors.connectionFailed") }}
        </p>
        <ws-button class="player-wrapper__error-message-repeat" color="primary" @click="play">
          {{ $t("broadcast.repeatConnect") }}
        </ws-button>
      </div>
    </div>
    <transition name="fade">
      <broadcast-screen-saver
        v-if="displayScreenSaver && stream && !pending"
        :image-src="screenSaverSrc"
        :disable-timer="isViewer"
        @screen-saver-hide="$emit('screen-saver-hide')"
      />
    </transition>
  </div>
</template>

<script>
import {
  NGENIX_RTMP_URL,
  PROVIDERS_TYPES,
  STREAM_TYPES,
  BITRATE_BY_RESOLUTION,
  STRATEGY_TYPES,
  CAPTURE_STREAM_STATUSES,
  RESOLUTIONS,
} from "@/constants/broadcast/broadcast-const";
import WsButton from "@/components/base/WsButton";
import WsIcon from "@/components/base/WsIcon";
import BroadcastScreenSaver from "@/components/common/broadcast/BroadcastScreenSaver";
import BroadcastWebRtcPlayerControls from "./BroadcastWebRtcPlayerControls";
import BroadcastQualitySelect from "./BroadcastQualitySelect";
import flashphonerMixin from "./mixins/flashphonerMixin";

const debug = process.env.NODE_ENV !== "development";

export default {
  name: "BroadcastWebRtcPlayerOne2Many",
  components: {
    WsIcon,
    WsButton,
    BroadcastWebRtcPlayerControls,
    BroadcastQualitySelect,
    BroadcastScreenSaver,
  },
  mixins: [flashphonerMixin],
  props: {
    resolution: {
      type: Object,
      default: () => RESOLUTIONS["240p"],
    },
    room: {
      type: String,
      default: "",
    },
    streamName: {
      type: String,
      default: "",
    },
    streamCaptureName: {
      type: String,
      default: "",
    },
    constraints: {
      type: Object,
      default: () => ({ audio: true, video: true }),
    },
    userId: {
      type: Number,
      default: 0,
    },
    userAvatar: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: STREAM_TYPES.SPEAKER,
    },
    urlServer: {
      type: String,
      required: true,
    },
    rtmp: {
      type: Boolean,
      default: false,
    },
    rtmpUrl: {
      type: String,
      default: NGENIX_RTMP_URL,
    },
    onAir: {
      type: Boolean,
      default: false,
    },
    speakerVideoMuted: {
      type: Boolean,
      default: false,
    },
    record: {
      type: Boolean,
      default: false,
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
    preventDisconnect: {
      type: Boolean,
      default: false,
    },
    screenSaverSrc: {
      type: String,
      default: "",
    },
    displayScreenSaver: {
      type: Boolean,
      default: false,
    },
    onStopRecording: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      stream: null,
      streamCapture: null,
      session: null,
      debug,
      mediaProviders: [],
      pending: false,
      logs: [],
      audioMuted: false,
      videoMuted: false,
      paused: false,
      error: "",
      selectedQuality: {
        name: this.resolution.name,
        width: this.resolution.width,
        height: this.resolution.height,
        bitrate: BITRATE_BY_RESOLUTION[this.resolution.name].maxBitrate,
      },
      destroying: false,
      volume: 100,
    };
  },
  computed: {
    localPlayerVideo() {
      return this.$refs.localPlayer.querySelector("video");
    },
    streamConstraints() {
      if (!this.constraints) return { audio: true, video: true };
      let { width } = this.resolution;
      let { height } = this.resolution;
      if (this.$isSafariWebRTC) {
        width = {
          ideal: this.resolution.width,
        };
        height = {
          ideal: this.resolution.height,
        };
      }
      const video = {
        frameRate: 30,
        deviceId: {
          exact: this.constraints.video.deviceId,
        },
        label: this.constraints.video.label,
        facingMode: this.constraints.video.facingMode,
        width,
        height,
        ...BITRATE_BY_RESOLUTION[this.resolution.name],
      };

      if (this.isSafariWebRTCIOS && !this.$isCordovaIOS) {
        delete video.width;
        delete video.height;
      }

      return {
        audio: this.constraints.audio,
        video,
      };
    },
    streamCaptureConstraints() {
      return {
        video: {
          width: this.resolution.width,
          height: this.resolution.height,
          frameRate: 30,
          type: "screen",
          mediaSource: "screen",
          withoutExtension: true,
          ...BITRATE_BY_RESOLUTION[this.resolution.name],
        },
        audio: false,
      };
    },
    isSpeaker() {
      return this.type === STREAM_TYPES.SPEAKER;
    },
    mediaProvider() {
      return this.$isCordovaIOS ? PROVIDERS_TYPES.WEBRTC : this.mediaProviders[0];
    },
    isWsMediaProvider() {
      return this.mediaProvider === PROVIDERS_TYPES.WS_PLAYER;
    },
    isViewer() {
      return this.type === STREAM_TYPES.VIEWER;
    },
    hasError() {
      return this.error && this.isViewer;
    },
    strategy() {
      return STRATEGY_TYPES.ONE_TO_MANY;
    },
    screenShareAllowed() {
      // allow capturing only low-latency and after main stream started
      return this.stream && this.$hasScreenCapture;
    },
    screenShareRun() {
      return !!this.streamCaptureName;
    },
    isDevMode() {
      return process.env.NODE_ENV === "development";
    },
    isSafariWebRTCIOS() {
      return this.$isSafariWebRTC && this.$isIOS;
    },
    hasVideoNegativeIndex() {
      return (
        (this.$isCordovaIOS && !this.inFullscreenMode && !this.isSpeaker) ||
        (this.isSpeaker && this.videoMuted)
      );
    },
    inFullscreenMode() {
      return this.$fullscreen.isFullscreen && this.$fullscreen.group === "broadcast";
    },
  },
  watch: {
    speakerVideoMuted: {
      handler(value) {
        this.videoMuted = value;
      },
      immediate: true,
    },
    selectedQuality() {
      if (this.stream) {
        this.restart();
      }
    },
    streamCaptureName(value) {
      if (this.isViewer) {
        if (value && this.stream) {
          this.startCaptureStream();
        } else {
          this.stopCaptureStream();
        }
      }
    },
    displayScreenSaver(value) {
      if (this.isSpeaker && (!this.audioMuted || (!value && this.audioMuted))) {
        this.toggleAudio();
      }
    },
  },
  async mounted() {
    this.pending = this.isSpeaker;
    await this.initFlashphoner(this.isSpeaker);
    this.init();
  },
  beforeDestroy() {
    this.destroying = true;
    this.stop();

    if (this.inFullscreenMode) {
      this.$fullscreen.exit("broadcast");
      this.$emit("fullscreen", false);
    }
  },
  methods: {
    init() {
      this.mediaProviders = this.$FP.getMediaProviders();
      if (this.isSpeaker || this.autoplay) {
        this.start();
      }
    },
    async play() {
      if (this.error) {
        this.error = "";
      }
      if (this.stream && this.paused) {
        this.playVideoElement();
        return;
      }
      this.pending = true;
      if (this.isWsMediaProvider) {
        this.$FP.playFirstSound();
      }
      this.start();
    },
    handleFailed() {
      this.pending = false;
      this.$emit("error");
      this.showNotification("error", this.SESSION_STATUS.FAILED);
    },
    async start(restart) {
      try {
        if (this.$FP.getSessions().length > 0) {
          [this.session] = this.$FP.getSessions();
          this.createStream(restart);
          if (this.screenShareRun) {
            this.startCaptureStream();
          }
        } else {
          this.setSessionListeners(
            this.$FP.createSession({
              urlServer: this.urlServer,
            }),
          );
        }
      } catch (e) {
        this.handleFailed();
      }
    },
    setSessionListeners(session) {
      this.session = session
        .on(this.SESSION_STATUS.ESTABLISHED, async () => {
          this.createStream();
          if (this.screenShareRun) {
            this.startCaptureStream();
          }
        })
        .on(this.SESSION_STATUS.FAILED, () => {
          this.showNotification("error", this.SESSION_STATUS.FAILED);
          this.handleFailed();
          this.session = null;
          this.log(new Error("Session Failed"));
        })
        .on(this.SESSION_STATUS.DISCONNECTED, () => {
          this.session = null;
        });
    },
    getOptions(capture) {
      const namePartByDomain = this.isDevMode ? "dev" : window.location.hostname.split(".")[0];
      const name = this.isViewer
        ? capture
          ? this.streamCaptureName
          : this.streamName
        : `${capture ? "capture_" : ""}${this.room}_${namePartByDomain}_${
            this.userId
          }_${Date.now()}`;

      const options = {
        name,
        display: this.$refs[capture ? "capturePlayer" : "localPlayer"],
        mediaProvider: this.mediaProvider,
        record: this.record,
        cacheLocalResources: true,
        disableConstraintsNormalization: this.$isSafariWebRTC || this.$isCordovaIOS,
        constraints: this.getConstraints(capture),
      };
      if (!this.$isIOS && (this.$checkBrowser("Yandex Browser") || this.$checkBrowser("Opera"))) {
        options.stripCodecs = "h264,H264";
      }

      if (this.rtmp) {
        options.rtmpUrl = this.rtmpUrl;
      }
      return options;
    },
    getConstraints(capture) {
      if (capture) {
        return this.streamCaptureConstraints;
      }
      if (this.isSpeaker) {
        return this.streamConstraints;
      }
      return {
        audio: true,
        video: {
          width: this.selectedQuality.width,
          height: this.selectedQuality.height,
          bitrate: this.selectedQuality.bitrate,
          quality: 0,
        },
      };
    },
    createStream(restart) {
      const options = this.getOptions();
      this.log(`STREAM OPTIONS`, {
        extra: {
          options: JSON.stringify({ ...options, display: "" }),
        },
        level: this.$sentryLoggerLevels.Info,
      });
      this.setStreamListeners(this.session.createStream(options), options.name, restart);
      if (this.isViewer) {
        this.stream.play();
      } else {
        this.stream.publish();
      }
    },
    setStreamListeners(stream, name, restart) {
      this.stream = stream
        .on(this.STREAM_STATUS.PLAYING, () => {
          this.pending = false;
          this.prepareLocalVideo();
          if (!restart) {
            this.$emit("play");
          }
        })
        .on(this.STREAM_STATUS.STOPPED, () => {
          this.handleStopStream();
        })
        .on(this.STREAM_STATUS.PUBLISHING, stream => {
          this.pending = false;
          this.log(`${this.STREAM_STATUS.PUBLISHING}`, {
            extra: {
              name: stream.name(),
            },
            level: this.$sentryLoggerLevels.Info,
          });

          this.setAdditionalAttrsToVideo(this.localPlayerVideo);
          this.playVideoElement();
          this.$emit("set-stream-name", name);
          this.$emit("publishing");
          this.showNotification("success", this.STREAM_STATUS.PUBLISHING);
        })
        .on(this.STREAM_STATUS.FAILED, stream => {
          this.log(new Error(`${this.STREAM_STATUS.FAILED}`), {
            extra: {
              info: stream.getInfo(),
            },
            level: this.$sentryLoggerLevels.Critical,
          });
          this.error = stream.getInfo();
          this.handleFailed();
          this.handleStopStream();
        })
        .on(this.STREAM_STATUS.UNPUBLISHED, stream => {
          if (this.record) {
            this.onStopRecording(stream.getRecordInfo());
          }
          this.handleStopStream();
        });
      this.connectionQualityUpdateHandler(stream);
    },
    stop() {
      this.stopVideoTracks(this.localPlayerVideo);
      this.removeVideoListeners(this.localPlayerVideo);
      this.stopCaptureStream();
      if (this.stream) {
        this.stream.stop();
        return new Promise(resolve => {
          setTimeout(() => {
            resolve();
          }, 5000);
        });
      }
      if (this.session && !this.stream && !this.preventDisconnect) {
        this.session.disconnect();
      }
      if (this.$refs.localPlayer) {
        this.$refs.localPlayer.innerHTML = "";
      }

      return true;
    },
    handleStopStream() {
      if (!this.preventDisconnect) {
        this.session.disconnect();
      }
      this.stream = null;
    },
    async fullscreen() {
      if (this.inFullscreenMode) {
        await this.$fullscreen.exit("broadcast");
      } else {
        await this.$fullscreen.enter("broadcast");
      }
    },
    playVideoElement() {
      const video = this.$refs.localPlayer.querySelector("video");
      if (video) {
        video.play();
      }
    },
    toggleAudio() {
      if (this.stream.isAudioMuted()) {
        this.stream.unmuteAudio();
      } else {
        this.stream.muteAudio();
      }
      this.audioMuted = !this.audioMuted;
    },
    toggleVolume() {
      this.audioMuted = !this.audioMuted;
      const volume = Number(!this.audioMuted);
      this.localPlayerVideo.muted = this.audioMuted;
      this.localPlayerVideo.volume = volume;
      this.volume = volume * 100;
      this.changeScreenShareVolume();
    },
    toggleVideo() {
      if (this.stream.isVideoMuted()) {
        this.stream.unmuteVideo();
      } else {
        this.stream.muteVideo();
      }
      this.videoMuted = !this.videoMuted;
      this.$emit("video-muted", this.videoMuted);
    },
    setAdditionalAttrsToVideo(video) {
      video.setAttribute("playsinline", "playsinline");
      video.setAttribute("autoplay", "autoplay");
      video.setAttribute("disablePictureInPicture", "disablePictureInPicture");
    },
    prepareLocalVideo() {
      this.setAdditionalAttrsToVideo(this.localPlayerVideo);
      if (
        (this.isSafariWebRTCIOS || this.$isCordovaIOS) &&
        this.mediaProvider === PROVIDERS_TYPES.WEBRTC
      ) {
        setTimeout(() => {
          this.stream.muteVideo();
          this.stream.unmuteVideo();
        }, 1500);
      }
      if (this.localPlayerVideo) {
        this.audioMuted = this.localPlayerVideo.muted;
        this.volume = Number(!this.audioMuted) * 100;
        this.addVideoListeners(this.localPlayerVideo);
      }
    },
    addVideoListeners(video) {
      video.addEventListener("pause", this.videoPauseHandler);
      video.addEventListener("play", this.videoPlayHandler);
      video.addEventListener("volumechange", this.videoVolumeChangeHandler);
    },
    removeVideoListeners(video) {
      if (video) {
        video.removeEventListener("pause", this.videoPauseHandler);
        video.removeEventListener("play", this.videoPlayHandler);
        video.removeEventListener("volumechange", this.videoVolumeChangeHandler);
      }
    },
    // TODO Вынести в миксин
    stopVideoTracks(video) {
      if (!video?.srcObject) return;
      video.srcObject.getTracks().forEach(track => {
        track.stop();
        video.srcObject.removeTrack(track);
      });
    },
    videoPauseHandler() {
      this.paused = true;
    },
    videoPlayHandler() {
      this.paused = false;
    },
    videoVolumeChangeHandler(event) {
      this.audioMuted = event.target.muted;
      if (this.audioMuted) {
        this.volume = 0;
      }
    },
    showNotification(type, status) {
      if (!this.isSpeaker) return;

      const notification = {
        group: "broadcast",
        type,
        title: "",
        text: "",
        duration: 5000,
      };
      if (status === this.STREAM_STATUS.PUBLISHING && this.stream) {
        notification.title = this.$t("broadcast.streamConnectedTitle");
        notification.text = this.$t("broadcast.streamConnectedText");
      }

      if (status === this.SESSION_STATUS.FAILED) {
        if (this.onAir && this.stream) {
          notification.title = this.$t("broadcast.errors.streamErrorTitle");
          notification.text = this.$t("broadcast.errors.streamErrorText");
          notification.duration = 10000;
        } else {
          notification.title = this.$t("broadcast.errors.error");
          notification.text = this.$t("broadcast.errors.connectionFailed");
        }
      }

      if (status === CAPTURE_STREAM_STATUSES.PUBLISHED) {
        notification.title = this.$t("broadcast.streamCapturePublishedTitle");
      }
      if (status === CAPTURE_STREAM_STATUSES.STOPPED) {
        notification.title = this.$t("broadcast.streamCaptureStoppedTitle");
      }
      if (status === CAPTURE_STREAM_STATUSES.FAILED) {
        notification.title = this.$t("broadcast.streamCaptureFailedTitle");
      }

      if (notification.title || notification.text) {
        this.$notify(notification);
      }
    },
    restart() {
      this.stop();
      const interval = setInterval(() => {
        if (!this.session) {
          this.start(true);
          clearInterval(interval);
        }
      }, 100);
    },
    handleScreenShare() {
      this.screenShareRun ? this.stopCaptureStream() : this.startCaptureStream();
    },
    startCaptureStream() {
      const options = this.getOptions(true);
      this.log(`CAPTURE OPTIONS`, {
        extra: {
          options: JSON.stringify(options),
        },
        level: this.$sentryLoggerLevels.Info,
      });
      this.setCaptureStreamListeners(this.session.createStream(options), options.name);
      if (this.isViewer) {
        this.streamCapture.play();
      } else {
        this.streamCapture.publish();
      }
    },
    setCaptureStreamListeners(stream, name) {
      this.streamCapture = stream
        .on(this.STREAM_STATUS.PLAYING, stream => {
          const video = document.getElementById(stream.id());
          video.muted = this.audioMuted;
          video.volume = this.volume / 100;
          this.setAdditionalAttrsToVideo(video);
        })
        .on(this.STREAM_STATUS.STOPPED, () => {
          this.showNotification("success", CAPTURE_STREAM_STATUSES.STOPPED);
          this.handleStoppedCaptureStream();
        })
        .on(this.STREAM_STATUS.PUBLISHING, stream => {
          this.log("CAPTURE_PUBLISHING", {
            extra: {
              name: stream.name(),
            },
            level: this.$sentryLoggerLevels.Info,
          });
          this.$emit("set-capture-stream-name", name);
          this.showNotification("success", CAPTURE_STREAM_STATUSES.PUBLISHED);
          /*
           * User can stop sharing screen capture using Chrome "stop" button.
           * Catch onended video track event and stop publishing.
           */
          stream.tracks = document.getElementById(stream.id()).srcObject.getTracks();
          stream.tracks.forEach(track => {
            track.addEventListener("ended", this.stopCaptureStream);
          });
        })
        .on(this.STREAM_STATUS.FAILED, reason => {
          this.log(new Error("CAPTURE_FAILED"), {
            extra: {
              info: reason.getInfo(),
            },
            level: this.$sentryLoggerLevels.Critical,
          });
          this.showNotification("error", CAPTURE_STREAM_STATUSES.FAILED);
          this.handleStoppedCaptureStream();
        })
        .on(this.STREAM_STATUS.UNPUBLISHED, () => {
          if (!this.destroying) {
            this.showNotification("success", CAPTURE_STREAM_STATUSES.STOPPED);
          }
          this.handleStoppedCaptureStream();
        });
    },
    stopCaptureStream() {
      if (!this.streamCapture) return;
      this.streamCapture.tracks?.forEach(track => {
        track.removeEventListener("ended", this.stopCaptureStream);
        track.stop();
      });
      this.streamCapture.stop();
    },
    handleStoppedCaptureStream() {
      if (this.isSpeaker) {
        this.$emit("set-capture-stream-name", "");
      }
      this.streamCapture = null;
      if (this.$refs.capturePlayer) {
        this.$refs.capturePlayer.innerHTML = "";
      }
    },
    handlePlayerMouseMove() {
      this.$refs.playerControls?.throttledShowButtons();
    },
    handlePlayerMouseLeave() {
      this.$refs.playerControls?.stopShowButtons();
    },
    handlePlayerMouseEnter() {
      this.$refs.playerControls?.startShowButtons();
    },
    changeVolume(value) {
      this.localPlayerVideo.muted = value === 0;
      this.localPlayerVideo.volume = value / 100;
      this.volume = value;
      this.audioMuted = this.localPlayerVideo.muted;
      this.changeScreenShareVolume();
    },
    changeScreenShareVolume() {
      if (this.streamCapture) {
        const streamCaptureVideo = document.getElementById(this.streamCapture.id());
        streamCaptureVideo.muted = this.audioMuted;
        streamCaptureVideo.volume = this.volume / 100;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.player-wrapper {
  display: flex;
  width: 100%;
  max-width: 100%;
  height: 100%;
  background: #000;

  &--transparent {
    background: transparent;
  }

  &--fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 90;
    justify-content: center;
    width: 100vw;
    background: #000;
    animation: fillHeight 500ms ease-in forwards;
  }

  @keyframes fillHeight {
    50% {
      min-height: 50%;
    }

    100% {
      min-height: 100%;
    }
  }

  &__main-player {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    overflow: hidden;
  }

  &__local-player {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    background-color: #000;

    &--error {
      background: transparent url("./white-noise.gif") no-repeat;
      background-size: cover;
    }

    ::v-deep video {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 9;
      width: 100%;
      height: auto;
      transform: translate(-50%, -50%);
    }
  }

  &--transparent &__local-player {
    background: transparent;
  }

  &__capture-player {
    position: absolute;
    top: 0;
    left: 0;

    &--shown {
      z-index: 10;
    }
  }

  &__error-message {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin: 0;
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.6);

    &-text {
      margin: auto 0 0;
    }

    &-repeat {
      margin-top: auto;
      margin-bottom: 20px;
    }
  }

  &__avatar,
  &__avatar-stub {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__avatar-img {
    max-width: 100%;
    object-fit: cover;
  }

  &__avatar {
    z-index: 9;
    width: 100px;
    height: 100px;
    overflow: hidden;
    font-size: 36px;
    color: #fff;
    background-color: #6a6967;
    border-radius: 50%;
  }

  &__quality {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 30;
    display: none;
  }

  &__controls:hover &__quality {
    display: flex;
  }

  &--negative-index ::v-deep video {
    z-index: -1;
  }

  &__main-player--speaker {
    display: flex;
    flex-direction: column;
  }
}
</style>
