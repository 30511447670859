<template>
  <div class="player-bg-stub">
    <div v-if="isVideoMuted" class="player-bg-stub__wrapper">
      <div class="player-bg-stub__avatar">
        <img
          v-if="avatarSrc"
          class="player-bg-stub__img absolute-center"
          :src="avatarSrc"
          alt="Speaker avatar"
        />
        <ws-icon
          v-else
          class="player-bg-stub__icon absolute-center"
          color="white"
          regular
        >
          user
        </ws-icon>
      </div>
    </div>
    <div v-if="!iconsDisabled" class="player-bg-stub__icon-wrapper">
      <div v-if="isAudioMuted" class="player-bg-stub__mute-audio">
        <ws-icon color="danger" xl regular>microphone-slash</ws-icon>
      </div>
      <div v-if="isVideoMuted" class="player-bg-stub__mute-video">
        <ws-icon color="danger" xl regular>video-slash</ws-icon>
      </div>  
    </div>
  </div>
</template>

<script>
import WsIcon from "@/components/base/WsIcon";

export default {
  name: "BroadcastWebRtcPlayerStub",
  components: { WsIcon },
  props: {
    isVideoMuted: {
      type: Boolean,
      default: false,
    },
    isAudioMuted: {
      type: Boolean,
      default: false,
    },
    avatarSrc: {
      type: String,
      default: "",
    },
    iconsDisabled: {
      type: Boolean,
      default: false,
    }
  },
};
</script>

<style lang="less" scoped>
.mute-audio-video() {
  margin: 10px 10px;
  max-width: 100%;
  text-overflow: ellipsis;
  font-size: 1em;
  white-space: nowrap;
  border-radius: 20px;
}

.player-bg-stub {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  &__wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #717171;
  }

  &__avatar {
    position: relative;
    width: 25%;
    max-width: 80px;
    overflow: hidden;
    box-shadow: 0 0 0 0.4em #fff;
    border-radius: 50%;

    &::before {
      content: "";
      display: block;
      padding-top: 100%;
    }
  }

  &__img {
    max-width: 100%;
    object-fit: cover;
  }

  &__icon {
    font-size: 2em;
  }

  &__icon-wrapper {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &__mute-audio,
  &__mute-video {
    .mute-audio-video();
  }

}
</style>
