<template>
  <div id="player" ref="player" class="vPlayer-wrapper">
    <iframe
      class="frameYoutube"
      :src="currentUrl"
      frameborder="0"
      allow="autoplay; encrypted-media"
      allowfullscreen
    ></iframe>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { broadcast } from "@/store/modules/store.namespaces";
import { YT_BROADCAST_URL } from "@/store/modules/broadcast/getter-types";

export default {
  name: "YtPlayer",
  props: {
    url: {
      type: String,
      default: "",
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(broadcast, {
      ytUrl: YT_BROADCAST_URL,
    }),
    currentUrl() {
      return `${this.url || this.ytUrl}&autoplay=${+this.autoplay}`;
    },
  },
};
</script>

<style scoped lang="less">
.vPlayer-wrapper {
  height: 100%;
}

.frameYoutube {
  width: 100%;
  height: 100%;
}
</style>
