var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"fullscreen",rawName:"v-fullscreen",value:('broadcast'),expression:"'broadcast'"}],staticClass:"player-wrapper",class:{
    'player-wrapper--viewer': _vm.isViewer,
    'player-wrapper--fullscreen': _vm.inFullscreenMode && !_vm.$fullscreen.isEnabled,
    'player-wrapper--transparent': _vm.$isCordovaIOS && !_vm.speakerVideoMuted && _vm.stream && !_vm.isSpeaker,
    'player-wrapper--negative-index': _vm.hasVideoNegativeIndex,
  },on:{"mouseenter":_vm.handlePlayerMouseEnter,"mouseleave":_vm.handlePlayerMouseLeave,"mousemove":_vm.handlePlayerMouseMove}},[_c('div',{staticClass:"player-wrapper__main-player",class:{
      'player-wrapper__main-player--speaker': _vm.$isCordovaIOS && _vm.isSpeaker,
    }},[_c('div',{ref:"localPlayer",staticClass:"player-wrapper__local-player",class:{
        'player-wrapper__local-player--error': _vm.hasError,
      },attrs:{"id":"broadcastPlayer"}}),_c('div',{ref:"capturePlayer",staticClass:"player-wrapper__local-player player-wrapper__capture-player",class:{
        'player-wrapper__capture-player--shown': _vm.streamCapture,
      },attrs:{"id":"broadcastCapturePlayer"}}),(_vm.videoMuted && _vm.stream && !_vm.paused)?_c('div',{staticClass:"player-wrapper__avatar"},[(_vm.userAvatar)?_c('img',{staticClass:"player-wrapper__avatar-img",attrs:{"src":_vm.userAvatar,"alt":"Speaker avatar"}}):_c('ws-icon',{staticClass:"player-wrapper__avatar-stub",attrs:{"regular":""}},[_vm._v(" user ")])],1):_vm._e(),(!_vm.hasError)?_c('broadcast-web-rtc-player-controls',{ref:"playerControls",staticClass:"player-wrapper__controls",attrs:{"type":_vm.type,"strategy":_vm.strategy,"media-provider":_vm.mediaProvider,"video-muted":_vm.videoMuted,"audio-muted":_vm.audioMuted,"volume-muted":_vm.audioMuted,"fullscreen":_vm.inFullscreenMode,"played":_vm.stream && !_vm.pending && !_vm.paused,"screen-share-allowed":_vm.screenShareAllowed,"screen-share-run":_vm.screenShareRun,"volume":_vm.volume},on:{"play":_vm.play,"audio":_vm.toggleAudio,"video":_vm.toggleVideo,"volume":_vm.toggleVolume,"full-screen":_vm.fullscreen,"screen-share":_vm.handleScreenShare,"change-volume":_vm.changeVolume},scopedSlots:_vm._u([{key:"additionalControls",fn:function(ref){
      var show = ref.show;
return [(_vm.isViewer)?_c('broadcast-quality-select',{directives:[{name:"show",rawName:"v-show",value:(show),expression:"show"}],staticClass:"player-wrapper__quality",attrs:{"max-quality":_vm.resolution},model:{value:(_vm.selectedQuality),callback:function ($$v) {_vm.selectedQuality=$$v},expression:"selectedQuality"}}):_vm._e()]}}],null,false,3167883854)}):_vm._e(),(_vm.hasError)?_c('div',{staticClass:"player-wrapper__error-message"},[_c('p',{staticClass:"player-wrapper__error-message-text"},[_vm._v(" "+_vm._s(_vm.$t("broadcast.errors.connectionFailed"))+" ")]),_c('ws-button',{staticClass:"player-wrapper__error-message-repeat",attrs:{"color":"primary"},on:{"click":_vm.play}},[_vm._v(" "+_vm._s(_vm.$t("broadcast.repeatConnect"))+" ")])],1):_vm._e()],1),_c('transition',{attrs:{"name":"fade"}},[(_vm.displayScreenSaver && _vm.stream && !_vm.pending)?_c('broadcast-screen-saver',{attrs:{"image-src":_vm.screenSaverSrc,"disable-timer":_vm.isViewer},on:{"screen-saver-hide":function($event){return _vm.$emit('screen-saver-hide')}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }