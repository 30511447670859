var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"broadcast-web-rtc-player-controls",class:{
    'broadcast-web-rtc-player-controls--full-screen': _vm.fullscreen,
    'broadcast-web-rtc-player-controls--side-visible': _vm.isSidePanelVisible,
    'broadcast-web-rtc-player-controls--show-buttons': _vm.buttonsShowed,
    'broadcast-web-rtc-player-controls--talking': _vm.talking,
    'broadcast-web-rtc-player-controls--leading': _vm.isTypeLeading,
    'broadcast-web-rtc-player-controls--static': _vm.isStaticControls && !_vm.fullscreen,
  }},[_c('transition',{attrs:{"name":"fade"}},[_vm._t("additionalControls",null,null,{ show: _vm.buttonsShowed })],2),(_vm.isShowHandsUp)?_c('ws-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.buttonsShowed),expression:"buttonsShowed"}],staticClass:"broadcast-web-rtc-player-controls__btn broadcast-web-rtc-player-controls__hands-up",attrs:{"rounded":"","color":"primary","sm":"","disabled":!_vm.canPresent && _vm.handsUpDisabled},on:{"click":function($event){return _vm.$emit('hands-up')}}},[_vm._v(" "+_vm._s(_vm.$t("broadcast.raiseHand"))+" ")]):_vm._e(),(_vm.isShowStartStream)?_c('ws-button',{staticClass:"broadcast-web-rtc-player-controls__start-translation broadcast-web-rtc-player-controls__btn",class:{ 'broadcast-web-rtc-player-controls--active-stream': _vm.activeStream },attrs:{"outlined":"","icon":_vm.activeStream && _vm.$mqMobileS,"sm":!(_vm.activeStream && _vm.$mqMobileS),"lg":_vm.activeStream && _vm.$mqMobileS,"title":_vm.activeStream ? _vm.goOffOnAirText : '',"color":"primary"},on:{"click":function($event){return _vm.$emit('connect-to-on-air')}}},[(!_vm.activeStream)?_c('ws-icon',{staticClass:"broadcast-web-rtc-player-controls__icon"},[_vm._v("play")]):_c('ws-icon',{staticClass:"broadcast-web-rtc-player-controls__icon",attrs:{"light":"","xxl":_vm.activeStream && _vm.$mqMobileS,"color":"danger"}},[_vm._v(" sign-out-alt ")]),_vm._v(" "+_vm._s(!_vm.activeStream ? _vm.$t("broadcast.goOnAir") : _vm.goOffOnAirText)+" ")],1):_vm._e(),_vm._l((_vm.buttons),function(button){
  var _obj;
return _c('transition',{key:button.name,attrs:{"name":"fade"}},[_c('ws-button',{directives:[{name:"show",rawName:"v-show",value:(button.isShow && (_vm.isTypeSpeaker || !_vm.isRemote)),expression:"button.isShow && (isTypeSpeaker || !isRemote)"}],class:[
        'broadcast-web-rtc-player-controls__btn',
        ("broadcast-web-rtc-player-controls__" + (button.name)),
        'broadcast-web-rtc-player-controls__icons',
        ( _obj = {}, _obj[("broadcast-web-rtc-player-controls__" + (button.name) + "--active")] = button.active, _obj['broadcast-web-rtc-player-controls__btn--visible'] =  button.persistVisible, _obj ) ],attrs:{"icon":"","lg":"","rounded":button.name === 'play',"fab":_vm.fullscreen && _vm.$mqLaptop,"color":_vm.fullscreen ? 'default' : '',"title":button.title,"disabled":button.disabled},on:{"click":function($event){$event.stopPropagation();return _vm.$emit(button.emit)}}},[_c('ws-icon',{attrs:{"light":button.name !== 'play',"xxl":button.name !== 'play'}},[_vm._v(" "+_vm._s(button.icon)+" ")])],1)],1)}),_c('transition',{attrs:{"name":"fade"}},[(_vm.isTypeViewer || _vm.isTypeLeading)?_c('broadcast-volume-control',{directives:[{name:"show",rawName:"v-show",value:(_vm.buttonsShowed || _vm.volumeMuted),expression:"buttonsShowed || volumeMuted"}],staticClass:"broadcast-web-rtc-player-controls__volume-control",attrs:{"fullscreen":_vm.fullscreen,"volume":_vm.volume,"volume-muted":_vm.volumeMuted},on:{"volume":function($event){return _vm.$emit('volume')},"change-volume":_vm.changeVolume}}):_vm._e()],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }