import "video.js/dist/video-js.css";

window.VIDEOJS_NO_BASE_THEME = true;
export default {
  data() {
    return {
      videojs: null,
    };
  },
  methods: {
    async getVideoJs() {
      const videojs = await import(/* webpackChunkName="vendor-videojs"*/ "video.js");
      this.videojs = videojs.default;
    },
  },
};
