<template>
  <component :is="player" v-bind="playerProps" v-on="$listeners" />
</template>

<script>
import BroadcastWebRtcPlayerConference from "@/components/common/broadcast/BroadcastWebRtcPlayerConference";
import BroadcastWebRtcPlayerOne2Many from "@/components/common/broadcast/BroadcastWebRtcPlayerOne2Many";
import BroadcastExternalPlayers from "@/components/common/broadcast/BroadcastExternalPlayers";
import { PLAYER_TYPE } from "@/constants/broadcast/broadcast-const";

const PLAYER_MAP = {
  [PLAYER_TYPE.CONFERENCE]: "BroadcastWebRtcPlayerConference",
  [PLAYER_TYPE.ONE_TO_MANY]: "BroadcastWebRtcPlayerOne2Many",
  [PLAYER_TYPE.EXTERNAL]: "BroadcastExternalPlayers",
};

export default {
  name: "BroadcastPlayerByType",
  components: {
    BroadcastWebRtcPlayerOne2Many,
    BroadcastWebRtcPlayerConference,
    BroadcastExternalPlayers,
  },
  props: {
    playerType: {
      type: String,
      default: "",
    },
    playerProps: {
      type: Object,
      require: true,
      default: () => ({}),
    },
  },
  computed: {
    player() {
      return PLAYER_MAP[this.playerType];
    },
  },
};
</script>
