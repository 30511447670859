<template>
  <div>
    <ws-tooltip
      v-if="multilingual"
      ref="langSelect"
      :class="$style.langSelect"
      trigger="click"
      interactive
      placement="bottom"
      :offset="[0, 0]"
      @show="opened = true"
      @hide="opened = false"
    >
      <template #target>
        <div :class="$style.langSelectBtn">
          {{ selectedLanguage }}
          <ws-icon light>{{ opened ? "chevron-up" : "chevron-down" }}</ws-icon>
        </div>
      </template>
      <template #content>
        <div
          v-for="lang in languages"
          :key="lang"
          :class="[$style.langSelectItem, { [$style.selected]: lang === selectedLanguage }]"
          @click="selectLanguage(lang)"
        >
          {{ lang }}
        </div>
      </template>
    </ws-tooltip>
    <video id="player" ref="videoPlayer" class="video-js" playsinline />
  </div>
</template>

<script>
import "videojs-contrib-hls";
import { mapState, mapMutations } from "vuex";
import { WsTooltip } from "@whenspeak/ui-kit";
import { setPlayEvents } from "@/utils";
import { room } from "@/store/modules/store.namespaces";
import { SET_SELECTED_STREAM_LANG } from "@/store/modules/common/mutation-types";
import config from "@/settings/config";
import videojsMixin from "@/mixins/players/videojs.mixin";
import WsIcon from "@/components/base/WsIcon";

export default {
  components: {
    WsTooltip,
    WsIcon,
  },
  mixins: [videojsMixin],
  props: {
    providedUrl: {
      type: String,
      default: "",
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
    multilingual: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      player: null,
      languages: ["RUS", "ENG"],
      opened: false,
    };
  },
  computed: {
    ...mapState(room, {
      room: "roomNumber",
      streamLang: "streamLang",
    }),
    selectedLanguage: {
      get() {
        return this.streamLang;
      },
      set(val) {
        this.setSelectedStreamLang(val);
      },
    },
  },
  watch: {
    async selectedLanguage() {
      this.player.src(await this.getHlsUrl());
    },
  },
  async mounted() {
    await this.getVideoJs();
    await this.initPlayer();
  },
  beforeDestroy() {
    this.dispose();
  },
  methods: {
    ...mapMutations(room, {
      setSelectedStreamLang: SET_SELECTED_STREAM_LANG,
    }),
    dispose() {
      if (this.player) {
        this.player.dispose();
      }
    },
    async initPlayer(restart) {
      this.player = this.videojs(this.$refs.videoPlayer, await this.getPlayerOptions(), () => {
        this.setPlaceholder();
        if (restart) {
          this.player.play();
        }
      });
      this.player.on("play", () => {
        this.$emit("play");
      });
      this.player.on("pause", () => {
        this.$emit("pause");
      });

      const self = this;
      this.player.on("error", async function () {
        const code = +this.error().code;
        if (code === MediaError.MEDIA_ERR_DECODE) {
          // method from player instance
          this.reloadSourceOnError(await self.getPlayerOptions());
          this.errorDisplay.close();
          if (!this.paused()) {
            this.play();
          }
        }
        setTimeout(self.initPlayer.bind(self, true), 1000);
      });
      this.player.userActive(false);
      setPlayEvents();
    },
    setPlaceholder() {
      this.$el
        .querySelector(".vjs-icon-placeholder")
        .setAttribute("data-content", this.$t("common.videoText"));
    },
    selectLanguage(lang) {
      this.selectedLanguage = lang;
      this.$refs.langSelect.hide();
    },
    async getPlayerOptions() {
      return {
        controls: true,
        autoplay: this.autoplay,
        preload: "auto",
        width: 320,
        height: 180,
        aspectRatio: "16:9",
        fluid: true,
        sources: [
          {
            src: await this.getHlsUrl(),
            type: "application/x-mpegURL",
          },
        ],
      };
    },
    async getHlsUrl() {
      if (this.multilingual) {
        const streamKey = this.multilingual
          ? `${this.room}_${this.selectedLanguage.toLowerCase()}`
          : this.room;
        return this.providedUrl.replace(this.room, streamKey) || await config.fallbackHlsUrl(streamKey);
      }

      return this.providedUrl || await config.fallbackHlsUrl(this.room);
    },
  },
};
</script>

<style lang="scss" module>
.langSelect {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
}

.langSelectBtn {
  display: flex;
  justify-content: space-between;
  width: 80px;
  padding: 5px 10px;
  font-size: 14px;
  color: $white;
  cursor: pointer;
  background-color: rgba($black, 0.7);
  border: 1px solid $white;
  border-radius: 3px;
}

.langSelectItem {
  width: 80px;
  padding: 5px 10px;
  font-size: 14px;
  color: $white;
  cursor: pointer;
  background-color: rgba($black, 0.7);
  border: 1px solid $white;
}

.selected {
  color: var(--base-color);
}
</style>
