<template>
  <div :class="$style.controls">
    <div v-line-clamp="2" :class="$style.controls__speakerName">
      {{ speakerName }}
    </div>
    <div v-if="buttonsShowed" :class="$style.controls__buttons">
      <ws-button
        v-for="button in controlButtons"
        :key="button.name"
        :class="$style.button_active"
        icon
        @click="$emit(button.name)"
      >
        <ws-icon :color="button.color" xxl light>
          {{ button.icon }}
        </ws-icon>
      </ws-button>
    </div>
  </div>
</template>

<script>
import WsButton from "@/components/base/WsButton";
import WsIcon from "@/components/base/WsIcon";

export default {
  name: "BroadcastSidePanelPlayerControls",
  components: { WsButton, WsIcon },
  props: {
    speakerName: {
      type: String,
      default: "",
    },
    videoMuted: {
      type: Boolean,
      default: false,
    },
    audioMuted: {
      type: Boolean,
      default: false,
    },
    buttonsShowed: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    controlButtons() {
      const btnFactory = (name, active, icon = name) => ({
        name,
        icon: active ? `${icon}-slash` : icon,
        color: active ? "default" : "white",
      });
      return [
        btnFactory("audio", this.audioMuted, "microphone"),
        btnFactory("video", this.videoMuted),
      ];
    },
  },
};
</script>

<style lang="scss" module scoped>
.controls {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  color: white;
  background: rgba($black, 0.2);

  &__buttons {
    display: grid;
    grid-template-columns: min-content min-content;
    gap: 10px;
  }
}
</style>
