<template>
  <div :class="$style.wrapper">
    <ws-button
      icon
      lg
      :fab="fullscreen && $mqLaptop"
      color="default"
      :class="[
        $style.button,
        {
          [$style.buttonVisible]: volumeMuted,
          [$style.buttonFullscreen]: fullscreen,
        },
      ]"
      @click.stop="$emit('volume')"
    >
      <ws-icon xxl light color="white"> {{ volumeIcon }} </ws-icon>
    </ws-button>
    <vue-slider
      v-if="!isSafariIOS"
      :value="volume"
      :class="$style.slider"
      :width="60"
      :height="2"
      :dot-size="10"
      :min="0"
      :max="100"
      lazy
      tooltip="none"
      @dragging="changeVolume($event)"
      @change="changeVolume($event)"
    />
  </div>
</template>

<script>
import WsButton from "@/components/base/WsButton";
import WsIcon from "@/components/base/WsIcon";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";

export default {
  name: "BroadcastVolumeControl",
  components: {
    WsButton,
    WsIcon,
    VueSlider,
  },
  props: {
    volume: {
      type: Number,
      default: 0,
    },
    volumeMuted: {
      type: Boolean,
      default: false,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    volumeIcon() {
      return this.volumeMuted ? "volume-slash" : "volume";
    },
    isSafariIOS() {
      return this.$isIOS && this.$isSafari;
    },
  },
  methods: {
    changeVolume(event) {
      this.$emit("change-volume", event);
    },
  },
};
</script>

<style lang="scss" module>
.wrapper {
  display: flex;
  align-items: center;
}

.button {
  &:hover {
    --button-bg-active-color: rgba(255, 255, 255, 0.2);
  }

  &Visible {
    background: rgba(#313131, 0.25);
  }

  &Fullscreen {
    background: rgba($white, 0.2);
    border-radius: 10px;
  }
}

.slider {
  margin-left: 10px;
}
</style>
