<template>
  <div
    class="broadcast-web-rtc-player-controls"
    :class="{
      'broadcast-web-rtc-player-controls--full-screen': fullscreen,
      'broadcast-web-rtc-player-controls--side-visible': isSidePanelVisible,
      'broadcast-web-rtc-player-controls--show-buttons': buttonsShowed,
      'broadcast-web-rtc-player-controls--talking': talking,
      'broadcast-web-rtc-player-controls--leading': isTypeLeading,
      'broadcast-web-rtc-player-controls--static': isStaticControls && !fullscreen,
    }"
  >
    <transition name="fade">
      <slot name="additionalControls" v-bind="{ show: buttonsShowed }"></slot>
    </transition>
    <ws-button
      v-show="buttonsShowed"
      v-if="isShowHandsUp"
      class="broadcast-web-rtc-player-controls__btn broadcast-web-rtc-player-controls__hands-up"
      rounded
      color="primary"
      sm
      :disabled="!canPresent && handsUpDisabled"
      @click="$emit('hands-up')"
    >
      {{ $t("broadcast.raiseHand") }}
    </ws-button>
    <ws-button
      v-if="isShowStartStream"
      outlined
      :icon="activeStream && $mqMobileS"
      :sm="!(activeStream && $mqMobileS)"
      :lg="activeStream && $mqMobileS"
      :title="activeStream ? goOffOnAirText : ''"
      color="primary"
      class="broadcast-web-rtc-player-controls__start-translation broadcast-web-rtc-player-controls__btn"
      :class="{ 'broadcast-web-rtc-player-controls--active-stream': activeStream }"
      @click="$emit('connect-to-on-air')"
    >
      <ws-icon v-if="!activeStream" class="broadcast-web-rtc-player-controls__icon">play</ws-icon>
      <ws-icon
        v-else
        light
        :xxl="activeStream && $mqMobileS"
        color="danger"
        class="broadcast-web-rtc-player-controls__icon"
      >
        sign-out-alt
      </ws-icon>
      {{ !activeStream ? $t("broadcast.goOnAir") : goOffOnAirText }}
    </ws-button>
    <transition v-for="button in buttons" :key="button.name" name="fade">
      <ws-button
        v-show="button.isShow && (isTypeSpeaker || !isRemote)"
        icon
        lg
        :rounded="button.name === 'play'"
        :fab="fullscreen && $mqLaptop"
        :color="fullscreen ? 'default' : ''"
        :class="[
          'broadcast-web-rtc-player-controls__btn',
          `broadcast-web-rtc-player-controls__${button.name}`,
          'broadcast-web-rtc-player-controls__icons',
          {
            [`broadcast-web-rtc-player-controls__${button.name}--active`]: button.active,
            'broadcast-web-rtc-player-controls__btn--visible': button.persistVisible,
          },
        ]"
        :title="button.title"
        :disabled="button.disabled"
        @click.stop="$emit(button.emit)"
      >
        <ws-icon :light="button.name !== 'play'" :xxl="button.name !== 'play'">
          {{ button.icon }}
        </ws-icon>
      </ws-button>
    </transition>
    <transition name="fade">
      <broadcast-volume-control
        v-if="isTypeViewer || isTypeLeading"
        v-show="buttonsShowed || volumeMuted"
        class="broadcast-web-rtc-player-controls__volume-control"
        :fullscreen="fullscreen"
        :volume="volume"
        :volume-muted="volumeMuted"
        @volume="$emit('volume')"
        @change-volume="changeVolume"
      />
    </transition>
  </div>
</template>

<script>
import throttle from "lodash/throttle";
import {
  PROVIDERS_TYPES,
  STREAM_TYPES,
  STRATEGY_TYPES,
} from "@/constants/broadcast/broadcast-const";
import WsButton from "@/components/base/WsButton";
import WsIcon from "@/components/base/WsIcon";
import BroadcastVolumeControl from "@/components/common/broadcast/BroadcastVolumeControl";

const SHOW_BUTTONS_DELAY = 3000;

export default {
  name: "BroadcastWebRtcPlayerControls",
  components: { WsButton, WsIcon, BroadcastVolumeControl },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    isRemote: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: STREAM_TYPES.VIEWER,
    },
    speakersCount: {
      type: Number,
      default: 0,
    },
    strategy: {
      type: String,
      default: STRATEGY_TYPES.CONFERENCE,
    },
    mediaProvider: {
      type: String,
      default: PROVIDERS_TYPES.WEBRTC,
    },
    speakerCount: {
      type: Number,
      default: 0,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    panelExpanded: {
      type: Boolean,
      default: true,
    },
    played: {
      type: Boolean,
      default: false,
    },
    activeStream: {
      type: Boolean,
      default: false,
    },
    videoMuted: {
      type: Boolean,
      default: false,
    },
    audioMuted: {
      type: Boolean,
      default: false,
    },
    volumeMuted: {
      type: Boolean,
      default: false,
    },
    /**
     * Speaker can present
     */
    canPresent: {
      type: Boolean,
      default: false,
    },
    /**
     * For additional classes if it is talking speaker
     */
    talking: {
      type: Boolean,
      default: false,
    },
    /**
     * Whether the user is allowed to show his screen.
     */
    screenShareAllowed: {
      type: Boolean,
      default: false,
    },
    /**
     * Does the user demonstrate his screen now.
     */
    screenShareRun: {
      type: Boolean,
      default: false,
    },
    /**
     * The demonstration is technically possible,
     * but is now prohibited by the relevant conditions/restrictions.
     */
    screenShareBlocked: {
      type: Boolean,
      default: false,
    },
    /**
     * Disable hands up if "Ask to air" request sent
     */
    handsUpDisabled: {
      type: Boolean,
      default: false,
    },
    volume: {
      type: Number,
      default: 100,
    },
  },
  data() {
    return {
      buttonsShowed: false,
      buttonsBlocked: true,
      showButtonsTimer: null,
      throttledShowButtons: () => null,
    };
  },
  computed: {
    isTypeViewer() {
      return this.type === STREAM_TYPES.VIEWER;
    },
    isTypeSpeaker() {
      return this.type === STREAM_TYPES.SPEAKER;
    },
    isTypeLeading() {
      return this.type === STREAM_TYPES.LEADING;
    },
    isOne2ManyStrategy() {
      return this.strategy === STRATEGY_TYPES.ONE_TO_MANY;
    },
    isConferenceStrategy() {
      return this.strategy === STRATEGY_TYPES.CONFERENCE;
    },
    isShowStartStream() {
      return (
        (this.$mqLaptop || this.inFullscreenMode) &&
        !this.isRemote &&
        !this.isTypeSpeaker &&
        this.strategy === STRATEGY_TYPES.CONFERENCE &&
        this.canPresent
      );
    },
    isShowFullScreen() {
      if (this.played && this.isOne2ManyStrategy && this.isTypeViewer) {
        return true;
      }
      return !this.isRemote && this.mediaProvider === PROVIDERS_TYPES.WEBRTC;
    },
    isSidePanelVisible() {
      return this.fullscreen && this.speakersCount > 1;
    },
    isShowAudioVideo() {
      return this.isTypeSpeaker || this.isTypeLeading;
    },
    buttons() {
      const icons = {
        play: !this.played ? "play" : "pause",
        video: this.videoMuted ? "video-slash" : "video",
        audio: this.audioMuted ? "microphone-slash" : "microphone",
        "full-screen": this.fullscreen ? "compress-alt" : "expand-alt",
        "side-panel": this.panelExpanded ? "times" : "plus",
        share: "rectangle-landscape",
      };
      const btnFactory = ({
        name,
        isShow,
        icon = icons[name],
        title = this.$t(`broadcast.title.${icon}`),
        emit = name,
        disabled = false,
        active = false,
        persistVisible = false,
      }) => ({
        name,
        isShow,
        icon,
        title,
        emit,
        disabled,
        active,
        persistVisible,
      });
      return [
        btnFactory({ name: "play", isShow: !this.played && !this.isTypeSpeaker }),
        btnFactory({
          name: "video",
          isShow: this.isShowAudioVideo && (this.buttonsShowed || this.videoMuted),
          persistVisible: this.videoMuted,
        }),
        btnFactory({
          name: "audio",
          isShow: this.isShowAudioVideo && (this.buttonsShowed || this.audioMuted),
          persistVisible: this.audioMuted,
        }),
        btnFactory({ name: "full-screen", isShow: this.isShowFullScreen && this.buttonsShowed }),
        btnFactory({ name: "side-panel", isShow: this.isSidePanelVisible && this.buttonsShowed }),
        btnFactory({
          name: "share",
          isShow: this.isShowScreenShare && (this.buttonsShowed || this.screenShareRun),
          emit: "screen-share",
          disabled: this.screenShareBlocked,
          active: this.screenShareRun,
          persistVisible: this.screenShareRun,
        }),
      ];
    },
    isShowScreenShare() {
      return this.screenShareAllowed && !this.isTypeViewer;
    },
    isStaticControls() {
      return this.isTypeSpeaker && this.$isMobile && this.isOne2ManyStrategy && this.$isCordovaIOS;
    },
    isShowHandsUp() {
      return (
        (this.$mqLaptop || this.inFullscreenMode) &&
        !this.isTypeSpeaker &&
        this.isConferenceStrategy &&
        !this.canPresent &&
        !this.isRemote
      );
    },
    showButtonsBlocked() {
      return this.buttonsBlocked || this.isStaticControls || this.disabled || !this.played;
    },
    inFullscreenMode() {
      return this.$fullscreen.isFullscreen && this.$fullscreen.group === "broadcast";
    },
    goOffOnAirText() {
      if (this.$mqMobileS) {
        return "";
      }
      return this.$mqToMobileL ? this.$t("broadcast.exit") : this.$t("broadcast.goOffAir");
    },
  },
  watch: {
    talking: {
      handler(value) {
        if (this.isTypeSpeaker) {
          this.buttonsShowed = value;
        }
      },
      immediate: true,
    },
  },
  created() {
    this.throttledShowButtons = throttle(this.showButtons, 1000);
    if (this.isStaticControls) {
      this.buttonsShowed = true;
    }
  },
  methods: {
    showButtons() {
      if (this.showButtonsBlocked) return;
      if (!this.buttonsShowed) this.buttonsShowed = true;
      this.clearShowButtonsTimer();
      this.showButtonsTimer = setTimeout(() => {
        this.buttonsShowed = false;
      }, SHOW_BUTTONS_DELAY);
    },
    startShowButtons() {
      this.buttonsShowed = true;
      this.buttonsBlocked = false;
    },
    stopShowButtons() {
      this.buttonsShowed = false;
      this.buttonsBlocked = true;
    },
    clearShowButtonsTimer() {
      if (!this.showButtonsTimer) return;
      clearTimeout(this.showButtonsTimer);
      this.showButtonsTimer = null;
    },
    emitScreenShare() {
      if (!this.screenShareBlocked) {
        this.$emit("screen-share");
      }
    },
    changeVolume(event) {
      this.$emit("change-volume", event);
    },
  },
};
</script>

<style lang="scss" scoped>
.broadcast-web-rtc-player-controls {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
  max-width: 100vw;
  max-height: 100vh;
  transition: background 300ms;

  &--show-buttons {
    background: rgba($black, 0.2);
  }

  &--talking {
    position: initial;
    height: 30px;
    margin-top: 5px;
    background: transparent;
  }

  &__start-translation,
  &__hands-up {
    position: absolute;
    top: 20px;
    left: 50%;
    display: none;
    transform: translateX(-50%);
  }

  &__start-translation {
    background-color: $white;
  }

  &--active-stream {
    top: 20px;
    left: 58%;
  }

  &--full-screen &--active-stream {
    top: 10px;
    left: 55%;

    @include breakpoint($mobile) {
      top: 20px;
    }

    @include mq-tablet {
      left: 50%;
    }

    @include mq-tablet-l {
      top: 30px;
    }
  }

  &__icons {
    position: absolute;
    display: flex;
    color: white;
    cursor: pointer;

    &--disabled {
      touch-action: none;
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  &__play {
    top: 50%;
    left: 50%;
    display: flex;
    width: 50px;
    height: 50px;
    background: rgba($white, 0.2);
    transform: translate(-50%, -50%);
  }

  &__volume {
    bottom: 10px;
    left: 10px;
  }

  &__audio {
    top: 10px;
    left: 10px;
  }

  &__video {
    top: 10px;
    left: 55px;
  }

  &__full-screen {
    top: 10px;
    right: 10px;
  }

  &__side-panel {
    top: 10px;
    right: 0;
    justify-content: normal;
    width: calc(var(--button-height) * 1.4);
    padding-left: 20px;
    border-radius: 10px 0 0 10px !important;
  }

  &__share {
    top: 10px;
    left: 100px;

    &::after {
      position: absolute;
      top: 15px;
      left: 12px;
      width: 2px;
      height: 2px;
      content: "";
      background-color: $white;
      border-radius: 50%;
    }

    &--active {
      color: var(--ws-color-danger);
    }
  }

  &--full-screen &__side-panel {
    @include mq-tablet-l {
      background: #222 !important;
    }
  }

  &--full-screen &__icons {
    @include mq-tablet-l {
      top: 30px;
      background: rgba($white, 0.2);
      border-radius: 10px;
    }
  }

  @include mq-tablet-l {
    &--full-screen &__audio {
      left: 30px;
    }

    &--full-screen &__video {
      left: 96px;
    }

    &--full-screen &__share {
      left: 162px;

      &::after {
        top: 23px;
        left: 20px;
      }
    }

    &--full-screen &__full-screen {
      right: 30px;
    }

    &--full-screen &__volume {
      top: unset;
      bottom: 30px;
      left: 30px;
    }

    &--full-screen &__volume-control {
      bottom: 30px;
      left: 30px;
    }
  }

  &--side-visible &__full-screen {
    right: 68px;
  }

  &--side-visible &__side-panel {
    background: $black;
  }

  &--talking &__btn {
    bottom: initial;
  }

  &--talking &__btn,
  &:hover &__btn {
    display: flex;
  }

  &__btn--visible,
  &__start-translation {
    display: flex;
  }

  &__btn--visible {
    background: rgba(#313131, 0.25);
  }

  &__volume-control {
    position: absolute;
    bottom: 10px;
    left: 10px;
  }

  &--static {
    position: static;
    display: flex;
    height: 40px;
    padding: 5px;
    background-color: $black;

    &:hover {
      background-color: $black;
    }
  }

  &--static &__btn {
    position: static;
    display: flex;
  }

  &--static &__btn {
    margin-right: 15px;
  }

  &--static &__full-screen {
    margin-right: 0;
    margin-left: auto;
  }
}
</style>
