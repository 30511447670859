<template>
  <div id="player" ref="player" class="vPlayer-wrapper">
    <div style="padding:75% 0 0 0;position:relative;">
      <iframe :src="currentUrl"
              style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { broadcast } from "@/store/modules/store.namespaces";
import { VIMEO_BROADCAST_URL } from "@/store/modules/broadcast/getter-types";

export default {
  name: "VimeoPlayer",
  props: {
    url: {
      type: String,
      default: "",
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(broadcast, {
      vimeoUrl: VIMEO_BROADCAST_URL,
    }),
    currentUrl() {
      return `${this.url || this.vimeoUrl}?autoplay=${+this.autoplay}&title=0&byline=0&portrait=0`;
    },
  },
};
</script>

<style scoped lang="less">
.vPlayer-wrapper {
  height: 100%;
}
</style>
