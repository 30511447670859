<template>
  <div :class="$style.wrapper">
    <ws-icon :class="$style.icon" light>webcam-slash</ws-icon>
    <span :class="$style.text">{{ message }}</span>
    <ws-button
      v-clipboard="redirectLink"
      color="primary"
      data-testid="openInBrowser"
      @click="handleBtnClick"
      >{{ buttonTitle }}</ws-button
    >
  </div>
</template>

<script>
import AuthService from "@/services/AuthService";
import config from "@/settings/config";
import WsIcon from "@/components/base/WsIcon";
import WsButton from "@/components/base/WsButton";

/**
 * Компонент для отображения сообщения о том что стрим заблокирован и трансляция не доступна для приложения
 * По клику на кнопку будет выполнен редирект в браузер/
 */
export default {
  name: "BroadcastBlockedMessage",
  components: {
    WsIcon,
    WsButton,
  },
  computed: {
    buttonTitle() {
      return this.$isCordova
        ? this.$t("broadcast.openInBrowser")
        : this.$t("broadcast.copyInBuffer");
    },
    message() {
      return this.$isCordova
        ? this.$t("broadcast.streamIsBlocked")
        : this.$t("broadcast.streamIsBlockedInBrowser");
    },
    redirectLink() {
      const host = this.$isCordova ? config.landingDomain : window.location.host;
      return `https://${host}/login?token=${AuthService.getToken()}&redirect=${
        this.$route.fullPath
      }`;
    },
  },
  methods: {
    handleBtnClick() {
      if (this.$isCordova) {
        window.open(this.redirectLink, "_system");
      }
    },
  },
};
</script>

<style lang="scss" module>
.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 20px 15px 20px;
  background-color: #363636;
}

.icon {
  font-size: 48px;
  color: #666;
}

.text {
  max-width: 320px;
  font-size: 15px;
  line-height: 1.3em;
  color: $white;
  text-align: center;

  @include mq-mobile {
    font-size: 12px;
  }
}
</style>
