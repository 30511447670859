<template>
  <div
    id="conferencePlayer"
    v-fullscreen="'broadcast'"
    :class="[$style.player, { [$style.player_mini]: !inFullscreenMode }]"
    @mousemove="handlePlayerMouseMove"
    @mouseleave="handlePlayerMouseLeave"
    @mouseenter="handlePlayerMouseEnter"
  >
    <div ref="stubPlayer" style="display: none"></div>
    <div :class="$style.player__container">
      <div :class="$style.player__content">
        <div
          v-show="!isOneSpeaker || !isTypeSpeaker || ownScreenShareRun"
          :class="[$style.speakerPlayer, $style.speakerPlayer_speak]"
        >
          <div v-show="!screenShareRun" :class="$style.speakerPlayer__inner">
            <video
              ref="talkingSpeakerVideo"
              playsinline="playsinline"
              autoplay="autoplay"
              muted="muted"
            />
          </div>
          <div ref="screenShare" :class="$style.speakerPlayer__inner" />
          <broadcast-web-rtc-player-stub
            v-show="!screenShareRun"
            :is-audio-muted="talkingSpeaker.settings.isAudioMuted"
            :is-video-muted="talkingSpeaker.settings.isVideoMuted"
            :avatar-src="talkingSpeaker.settings.avatar"
          />
        </div>
        <div
          v-if="!isTypeViewer"
          :class="[
            $style.speakerPlayer,
            {
              [$style.speakerPlayer_main]: !isOneSpeaker || ownScreenShareRun,
              [$style.speakerPlayer_speak]: isOneSpeaker && isTypeSpeaker,
            },
          ]"
        >
          <div ref="localSpeakerVideo" :class="$style.speakerPlayer__inner" />
          <broadcast-web-rtc-player-stub
            :is-audio-muted="localSpeaker.settings.isAudioMuted"
            :is-video-muted="localSpeaker.settings.isVideoMuted"
            :avatar-src="localSpeaker.settings.avatar"
            icons-disabled
          />
        </div>
        <transition name="fade">
          <loader v-if="pending" :class="$style.player__loader" />
          <broadcast-screen-saver
            v-else-if="displayScreenSaver && streamRoom"
            :image-src="screenSaverSrc"
            :disable-timer="isTypeViewer"
            @screen-saver-hide="$emit('screen-saver-hide')"
          />
          <broadcast-web-rtc-player-controls
            v-else
            ref="playerControls"
            v-bind="playerControlsConfig.props"
            v-on="playerControlsConfig.listeners"
          />
        </transition>
      </div>
      <div
        :class="[
          $style.player__sidePanel,
          { [$style.player__sidePanel_expanded]: !isOneSpeaker && sideExpanded },
        ]"
      >
        <div :class="$style.player__title">
          <span>{{ $t("broadcast.leadings") }}</span>
          <span :class="$style.player__counter">{{ speakers.length }}</span>
        </div>
        <div :class="$style.player__speakers">
          <div
            v-for="speaker in speakers"
            :ref="speaker.name()"
            :key="speaker.name()"
            :class="$style.speakerPlayer"
          >
            <div :ref="'player_' + speaker.name()" :class="$style.speakerPlayer__inner" />
            <broadcast-web-rtc-player-stub
              :is-audio-muted="speaker.settings.isAudioMuted"
              :is-video-muted="speaker.settings.isVideoMuted"
              :icons-disabled="speaker.name() === localSpeaker.name()"
              :avatar-src="speaker.settings.avatar"
            />
            <broadcast-side-panel-player-controls
              :class="$style.speakerPlayer__sideControls"
              :speaker-name="speaker.settings.name"
              :buttons-showed="isTypeSpeaker && speaker.name() !== nameForRoom"
              :audio-muted="speaker.settings.isAudioMuted"
              :video-muted="speaker.settings.isVideoMuted"
              @audio="toggleRemoteAudio(speaker)"
              @video="toggleRemoteVideo(speaker)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import throttle from "lodash/throttle";
import { nanoid } from "nanoid";
import flashphonerMixin from "@/components/common/broadcast/mixins/flashphonerMixin";
import BroadcastWebRtcPlayerControls from "@/components/common/broadcast/BroadcastWebRtcPlayerControls";
import BroadcastWebRtcPlayerStub from "@/components/common/broadcast/BroadcastWebRtcPlayerStub";
import Loader from "@/components/common/elements/Loader";
import {
  BITRATE_BY_RESOLUTION,
  STREAM_TYPES,
  USER_NAME_PREFIX,
  CAPTURE_STREAM_POSTFIX,
  CAPTURE_STREAM_STATUSES,
} from "@/constants/broadcast/broadcast-const";
import BroadcastScreenSaver from "@/components/common/broadcast/BroadcastScreenSaver";
import BroadcastSidePanelPlayerControls from "@/components/common/broadcast/BroadcastSidePanelPlayerControls";

const CHANGE_TALKING_SPEAKER_INTERVAL = 3000; // Интервал частоты смены говорящего спикера
const OBSERVE_SPEAKER_DELAY = 3000; // Задержка перед началом определения говорящего
const THRESH_HOLD_LEVEL = 0.05; // Уровень звука с которого считается что спикер начал говорить
const UPDATE_SPEECH_INTERVAL = 500; // Интервал обновления информации о том говорит ли спикер
const BOX_SHADOW = "0 0 0 3px #F79843";

const MESSAGE_TYPES = {
  SETTINGS: "settings",
  CHANGE_SETTINGS: "change-settings",
  CHANGE_TALKING_SPEAKER: "change-talking-speaker",
  HIGHLIGHT_TALKING: "highlight-talking",
};

export default {
  name: "BroadcastWebRtcPlayerConference",
  components: {
    BroadcastSidePanelPlayerControls,
    BroadcastScreenSaver,
    BroadcastWebRtcPlayerControls,
    BroadcastWebRtcPlayerStub,
    Loader,
  },
  mixins: [flashphonerMixin],
  props: {
    resolution: {
      type: Object,
      default: () => ({
        width: "320",
        height: "240",
      }),
    },
    room: {
      type: String,
      default: "",
    },
    constraints: {
      type: Object,
      default: () => ({ audio: true, video: true }),
    },
    onStopped: {
      type: Function,
      default: () => {},
    },
    userName: {
      type: String,
      default: "User Name",
    },
    userId: {
      type: Number,
      default: 0,
    },
    userAvatar: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: STREAM_TYPES.SPEAKER,
    },
    onAirAsLeading: {
      type: Boolean,
      default: false,
    },
    streamName: {
      type: String,
      default: "",
    },
    streamCaptureName: {
      type: String,
      default: "",
    },
    urlServer: {
      type: String,
      required: true,
    },
    canPresent: {
      type: Boolean,
      default: false,
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
    onlyFullScreen: {
      type: Boolean,
      default: false,
    },
    disabledControls: {
      type: Boolean,
      default: false,
    },
    screenSaverSrc: {
      type: String,
      default: "",
    },
    displayScreenSaver: {
      type: Boolean,
      default: false,
    },
    audioPermission: {
      type: Boolean,
      default: true,
    },
    videoPermission: {
      type: Boolean,
      default: true,
    },
    handsUpDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      stream: null,
      streamRoom: null,
      streamCapture: null,
      participantStreamCapture: null,
      session: null,
      audioContext: null,
      mediaProviders: [],
      pending: false,
      parentEl: null,
      sideExpanded: true,
      allParticipant: [],
      mainSpeaker: this.makeSpeaker(),
      localSpeaker: this.makeSpeaker(),
      talkingSpeaker: this.makeSpeaker(),
      speakers: [],
      throttledChangeTalkingSpeaker: () => {},
      participantCounter: 0,
      destroying: false,
      volume: 100,
    };
  },
  computed: {
    isTypeSpeaker() {
      return this.type === STREAM_TYPES.SPEAKER;
    },
    isTypeViewer() {
      return this.type === STREAM_TYPES.VIEWER;
    },
    isOneSpeaker() {
      return this.speakers.length < 2;
    },
    isSafariWebRTCIOS() {
      return this.$isSafariWebRTC && this.$isIOS;
    },
    streamConstraints() {
      if (!this.constraints) return { audio: true, video: true };
      let { width } = this.resolution;
      let { height } = this.resolution;
      if (this.$isSafariWebRTC) {
        width = {
          ideal: this.resolution.width,
        };
        height = {
          ideal: this.resolution.height,
        };
      }
      const video = {
        frameRate: 30,
        deviceId: {
          exact: this.constraints.video.deviceId,
        },
        label: this.constraints.video.label,
        facingMode: this.constraints.video.facingMode,
        width,
        height,
        ...BITRATE_BY_RESOLUTION[this.resolution.name],
      };

      if (this.isSafariWebRTCIOS) {
        delete video.width;
        delete video.height;
      }
      return {
        audio: this.constraints.audio,
        video,
      };
    },
    streamCaptureConstraints() {
      const constraints = {
        video: {
          width: this.resolution.width,
          height: this.resolution.height,
          frameRate: 30,
          type: "screen",
          mediaSource: "screen",
          withoutExtension: true,
        },
        audio: false,
      };

      if (this.resolution.name !== "Auto") {
        constraints.video = Object.assign(
          constraints.video,
          BITRATE_BY_RESOLUTION[this.resolution.name],
        );
      }
      return constraints;
    },
    playerControlsConfig() {
      return {
        props: {
          disabled: this.disabledControls,
          type: this.type,
          speakersCount: this.speakers.length,
          played: (this.stream || this.streamRoom) && !this.pending,
          activeStream: this.onAirAsLeading,
          fullscreen: this.inFullscreenMode,
          panelExpanded: this.sideExpanded,
          audioMuted: this.localSpeaker.settings.isAudioMuted,
          videoMuted: this.localSpeaker.settings.isVideoMuted,
          volumeMuted: this.volumeMuted,
          canPresent: this.canPresent,
          screenShareRun: this.ownScreenShareRun,
          screenShareAllowed: this.screenShareAllowed,
          screenShareBlocked: this.screenShareRun && !this.ownScreenShareRun,
          handsUpDisabled: this.handsUpDisabled,
          volume: this.volume,
        },
        listeners: {
          play: this.start,
          audio: this.toggleAudio,
          video: this.toggleVideo,
          volume: this.toggleVolume,
          settings: this.$emit.bind(this, "settings"),
          "full-screen": this.fullscreen,
          "side-panel": this.toggleSidePanel,
          "connect-to-on-air": this.goOnAir,
          "screen-share": this.handleScreenShare,
          "change-volume": this.changeVolume,
          "hands-up": this.$emit.bind(this, "hands-up"),
          "switch-camera": this.$emit.bind(this, "switch-camera"),
          "show-broadcast-popup": this.$emit.bind(this, "toggle-broadcast-popup"),
        },
      };
    },
    playing() {
      return this.stream && this.stream.status() === this.STREAM_STATUS.PLAYING;
    },
    nameForRoom() {
      const name = `#t{${USER_NAME_PREFIX[this.type]}}#id{${this.userId}}-${this.userName}-${nanoid(
        8,
      )}`;
      return encodeURIComponent(name);
    },
    screenShareAllowed() {
      // allow capturing only after main stream started
      return this.stream && this.$hasScreenCapture;
    },
    screenSharingSpeakerName() {
      if (this.streamCapture) {
        return this.nameForRoom;
      }
      if (this.participantStreamCapture) {
        const streamCaptureName = this.participantStreamCapture.name();
        const screenSharingSpeaker = this.speakers.find(
          speaker =>
            speaker.getStreams &&
            speaker.getStreams().find(stream => stream.streamName() === streamCaptureName),
        );
        if (screenSharingSpeaker) {
          return screenSharingSpeaker.name();
        }
      }
      return "";
    },
    screenShareRun() {
      return !!this.screenSharingSpeakerName;
    },
    ownScreenShareRun() {
      return !!this.streamCapture;
    },
    otherSpeakers() {
      return this.speakers.filter(speaker => this.nameForRoom !== speaker?.name());
    },
    volumeMuted() {
      return this.otherSpeakers.some(speaker => speaker.data?.volumeMuted);
    },
    inFullscreenMode() {
      return this.$fullscreen.isFullscreen && this.$fullscreen.group === "broadcast";
    },
  },
  watch: {
    async displayScreenSaver(val) {
      if (this.isTypeSpeaker) {
        if (val) {
          this.stream.muteAudio();
        } else if (!val && !this.localSpeaker.settings.isAudioMuted) {
          this.stream.unmuteAudio();
        }
      }
    },
    onAirAsLeading(val) {
      if (val && this.type === STREAM_TYPES.VIEWER) {
        this.$emit("allow-translation", STREAM_TYPES.LEADING);
      }
      if (!val && this.type === STREAM_TYPES.LEADING) {
        this.$emit("allow-translation", STREAM_TYPES.VIEWER);
      }
    },
    type(type) {
      if (type === STREAM_TYPES.VIEWER) {
        this.stopStream();
      } else if (type === STREAM_TYPES.LEADING) {
        this.startStream();
      }
    },
    async sideExpanded(val) {
      if (this.$isIOS && val) {
        await this.$nextTick();
        this.playVideoElement();
      }
    },
    audioPermission(value) {
      if (value !== !this.localSpeaker.settings.isAudioMuted && this.onAirAsLeading) {
        this.toggleAudio(true);
      }
    },
    videoPermission(value) {
      if (value !== !this.localSpeaker.settings.isVideoMuted && this.onAirAsLeading) {
        this.toggleVideo(true);
      }
    },
    volumeMuted(value) {
      if (value && this.volume !== 0) {
        this.volume = 0;
      }
    },
    "talkingSpeaker.data.videoEl": function(video) {
      if (video) {
        this.$refs.talkingSpeakerVideo.srcObject = video.srcObject;
        this.$refs.talkingSpeakerVideo.play();
      }
    },
    "localSpeaker.data.videoEl": function(video) {
      if (video) {
        const videoForSidebar = document.createElement("video");
        this.setAdditionalAttrsToVideo(videoForSidebar);
        videoForSidebar.muted = true;
        videoForSidebar.srcObject = video.srcObject;
        this.$refs[`player_${this.localSpeaker.name()}`][0].appendChild(videoForSidebar);
        this.pendingEnded();
      }
    },
  },
  async mounted() {
    await this.initFlashphoner();
    this.init();
    this.addListeners();
    this.throttledChangeTalkingSpeaker = throttle(speaker => {
      if (this.localSpeaker.name() !== speaker.name()) {
        this.talkingSpeaker = speaker;
      }
      if (this.isTypeSpeaker) {
        this.changeTalkingSpeakerHandler(speaker);
      }
    }, CHANGE_TALKING_SPEAKER_INTERVAL);
    if (this.onlyFullScreen) {
      await this.$nextTick();
      this.fullscreen();
    }
  },
  beforeDestroy() {
    if (this.inFullscreenMode) {
      this.fullscreen();
    }
    this.removeListeners();
    this.destroying = true;
    this.stop();
    document.body.removeAttribute("style");
  },
  methods: {
    init() {
      this.mediaProviders = this.$FP.getMediaProviders();
      if (this.type === STREAM_TYPES.SPEAKER || this.autoplay) {
        this.start();
      }
    },
    async start() {
      this.pending = true;
      if (this.isSafariWebRTCIOS && !this.isTypeViewer) {
        await this.$FP.getMediaDevices(null, this.$refs.stubPlayer);
      }
      this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
      this.startRoomSession();
    },
    startRoomSession() {
      this.session = this.$FP.roomApi
        .connect({
          urlServer: this.urlServer,
          username: this.nameForRoom,
        })
        .on(this.SESSION_STATUS.ESTABLISHED, () => {
          this.join();
        })
        .on(this.SESSION_STATUS.FAILED, () => {
          this.log(new Error("SESSION_FAILED"));
          this.stop();
          this.$emit("failed");
          this.showNotification("error", this.SESSION_STATUS.FAILED);
          this.pendingEnded();
        })
        .on(this.SESSION_STATUS.DISCONNECTED, () => {
          this.stop();
        });
    },
    join() {
      const namePartByDomain =
        process.env.NODE_ENV === "development" ? "dev" : window.location.hostname.split(".")[0];
      const name = !this.isTypeSpeaker
        ? this.streamName
        : `${this.room}_${namePartByDomain}_${this.userId}_${Date.now()}`;
      this.session
        .join({ name })
        .on(this.ROOM_EVENT.STATE, room => {
          this.streamRoom = room;
          this.allParticipant = room.getParticipants();
          if (this.isTypeSpeaker) {
            this.$emit("set-stream-name", name);
          }
          this.onJoinRoom(room);
        })
        .on(this.ROOM_EVENT.JOINED, participant => {
          this.allParticipant.push(participant);
          this.$emit("participant-join", decodeURIComponent(participant.name()));
          if (!this.isTypeViewer) {
            this.sendMySettings(participant);
          }
        })
        .on(this.ROOM_EVENT.PUBLISHED, async participant => {
          if (!this.speakers.some(speaker => speaker.name() === participant.name())) {
            this.makeSpeaker(undefined, participant);
            this.speakers.push(participant);
            this.$emit("participant-published", decodeURIComponent(participant.name()));
          }
          await this.$nextTick();
          this.playParticipantStream(participant);
        })
        .on(this.ROOM_EVENT.MESSAGE, this.onMessage)
        .on(this.ROOM_EVENT.LEFT, participant => {
          this.allParticipant = this.allParticipant.filter(p => p.name() !== participant.name());
          this.$emit("participant-left", decodeURIComponent(participant.name()));
        })
        .on(this.ROOM_EVENT.FAILED, () => {
          this.log(new Error("ROOM_FAILED"), {
            extra: {
              name: this.nameForRoom,
            },
            level: this.$sentryLoggerLevels.Critical,
          });
          this.streamRoom = null;
          this.stop();
          this.$emit("failed");
          this.showNotification("error", this.SESSION_STATUS.FAILED);
          this.pendingEnded();
        });
    },
    async onJoinRoom(room) {
      if (!this.isTypeViewer) {
        await this.publish(room);
        if (this.isTypeSpeaker) return;
      }
      const allSpeakers = this.allParticipant.filter(p => p.getStreams().length);
      this.mainSpeaker = allSpeakers.find(s => s.name().includes(USER_NAME_PREFIX.SPEAKER));
      const leadings = allSpeakers.filter(s => !s.name().includes(USER_NAME_PREFIX.SPEAKER));
      this.speakers = [...this.speakers, this.mainSpeaker, ...leadings];
      this.speakers.forEach((s, i) => {
        if (!i && !this.isTypeViewer) return;
        this.makeSpeaker(undefined, s);
      });
      await this.$nextTick();
      this.speakers.forEach((s, i) => {
        if (!i && !this.isTypeViewer) return;
        this.playParticipantStream(s);
      });
    },
    async publish(room) {
      const name = this.nameForRoom;
      this.localSpeaker = this.makeSpeaker(name);
      this.sendMySettings();
      this.speakers.unshift(this.localSpeaker);
      await this.$nextTick();
      const display = this.$refs.localSpeakerVideo;
      const options = {
        display,
        cacheLocalResources: true,
        record: true,
        disableConstraintsNormalization: this.isSafariWebRTCIOS,
        constraints: this.streamConstraints,
      };
      const stream = room
        .publish(options)
        .on(this.STREAM_STATUS.FAILED, stream => {
          this.log(new Error("STREAM_FAILED"), {
            extra: {
              name: stream.getInfo(),
            },
            level: this.$sentryLoggerLevels.Critical,
          });
          if (this.isTypeSpeaker) {
            this.stop();
          }
          this.$emit("failed");
          this.showNotification("error", this.SESSION_STATUS.FAILED);
        })
        .on(this.STREAM_STATUS.PUBLISHING, stream => {
          this.log("STREAM_PUBLISHING", {
            extra: {
              options: JSON.stringify({ ...options, display: "" }),
            },
            level: this.$sentryLoggerLevels.Info,
          });
          const video = document.getElementById(stream.id());
          this.setVideoListeners(this.localSpeaker, video);
          this.stream = stream;
          this.$emit("publishing");
          this.showNotification("success", this.STREAM_STATUS.PUBLISHING);
          if (this.isTypeSpeaker) {
            this.detectSpeech(this.localSpeaker, stream);
            this.resumeAudioCtx();
          }
        })
        .on(this.STREAM_STATUS.UNPUBLISHED, () => {
          this.stream = null;
          if (this.isSafariWebRTCIOS) {
            setTimeout(() => {
              this.playVideoElement();
            }, 500);
          }
          if (this.isTypeSpeaker) {
            this.stop();
          }
        })
        .on(this.STREAM_STATUS.STOPPED, () => {
          if (this.isTypeSpeaker) {
            this.stream = null;
            this.stop();
          }
        });
      this.connectionQualityUpdateHandler(stream);
    },
    playParticipantStream(participant) {
      const display = this.$refs[`player_${participant.name()}`][0];
      const displayCapture = this.$refs.screenShare;
      const stream = participant
        .getStreams()
        .find(stream => !stream.streamName().endsWith(CAPTURE_STREAM_POSTFIX));
      const speakerAlreadyStreaming = document.getElementById(stream.id());
      const streamCapture = participant
        .getStreams()
        .find(stream => stream.streamName().endsWith(CAPTURE_STREAM_POSTFIX));
      if (!speakerAlreadyStreaming) {
        stream
          .play(display)
          .on(this.STREAM_STATUS.PLAYING, stream => {
            const video = document.getElementById(stream.id());
            // eslint-disable
            /* Политика автоплея браузеров заставляет нас мьютать видео если пользователь не совершал действий
             * при этом иногда видео тег может быть не в мьюте, но аудио контекст в состоянии ожидания действий
             * в таких случаях нам нужно заставить участника совершить действие принудительно включив мьют видео тегов,
             * но на спикере аудиоконтекст сам выходит из этого состояния как только появляется звук от первого
             * подкл. ведущего ведь действия уже были совершенны.
             */
            // eslint-enable
            video.muted =
              this.volumeMuted || (this.audioContext.state === "suspended" && !this.isTypeSpeaker);
            this.setVideoListeners(participant, video);
            if (
              !this.talkingSpeaker.name() ||
              this.talkingSpeaker.name() === this.localSpeaker.name()
            ) {
              this.talkingSpeaker = participant;
            }

            if (this.isTypeSpeaker) {
              this.detectSpeech(participant, stream);
            }
          })
          .on(this.STREAM_STATUS.FAILED, stream => {
            this.log(new Error("STREAM_PLAY_FAILED"), {
              extra: {
                name: stream.name(),
                info: stream.getInfo(),
              },
              level: this.$sentryLoggerLevels.Critical,
            });
            this.speakers = this.speakers.filter(s => s.name() !== participant.name());
            if (this.isTypeSpeaker && this.stream) {
              clearInterval(participant.intervalId);
              if (participant.name() === this.talkingSpeaker.name()) {
                this.checkTalkingSpeaker(participant);
                this.changeTalkingSpeakerHandler(this.talkingSpeaker);
              }
              this.$emit("participant-left", decodeURIComponent(participant.name()), true);
            }
            if (this.pending) {
              this.pendingEnded();
            }
          });
      }
      if (!streamCapture) {
        return;
      }

      streamCapture
        .play(displayCapture)
        .on(this.STREAM_STATUS.PLAYING, stream => {
          this.participantStreamCapture = stream;
          const video = document.getElementById(stream.id());
          this.setAdditionalAttrsToVideo(video);
          video.muted = this.volumeMuted;
        })
        .on(this.STREAM_STATUS.FAILED, stream => {
          this.log(new Error("[PARTICIPANT] CAPTURE STREAM_FAILED"), {
            extra: {
              name: stream.name(),
            },
            level: this.$sentryLoggerLevels.Critical,
          });
          this.participantStreamCapture = null;
          this.allParticipant = this.streamRoom.getParticipants();
        });
    },
    startStream() {
      if (this.streamRoom) {
        this.publish(this.streamRoom);
      }
    },
    stopStream() {
      this.stopVideoTracks(this.localSpeaker.data.videoEl);
      this.stream?.stop();
      if (this.streamCapture) {
        this.stopCaptureStream();
      }
      if (!this.isTypeSpeaker) {
        this.speakers.shift();
      }
    },
    checkTalkingSpeaker(participant) {
      if (this.talkingSpeaker.name() === participant.name() && this.speakers.length > 1) {
        [, this.talkingSpeaker] = this.speakers;
      } else if (this.speakers.length === 1) {
        [this.talkingSpeaker] = this.speakers;
      }
    },
    toggleAudio(silent = false) {
      if (this.stream.isAudioMuted()) {
        this.localSpeaker.settings.isAudioMuted = false;
        this.stream.unmuteAudio();
      } else {
        this.localSpeaker.settings.isAudioMuted = true;
        this.stream.muteAudio();
      }
      this.sendMySettings();
      if (!silent) {
        this.$emit("toggle-audio", !this.localSpeaker.settings.isAudioMuted);
      }
    },
    toggleVolume() {
      this.otherSpeakers.forEach(speaker => {
        speaker.data.videoEl.muted = !this.volumeMuted;
        if (speaker.data.videoEl.muted) {
          speaker.data.videoEl.volume = 0;
          this.volume = 0;
        } else {
          speaker.data.videoEl.volume = 1;
          this.volume = 100;
        }
      });
      this.changeScreenShareVolume();
    },
    toggleVideo(silent = false) {
      if (this.stream.isVideoMuted()) {
        this.localSpeaker.settings.isVideoMuted = false;
        this.stream.unmuteVideo();
      } else {
        this.localSpeaker.settings.isVideoMuted = true;
        this.stream.muteVideo();
      }
      this.sendMySettings();
      if (!silent) {
        this.$emit("toggle-video", !this.localSpeaker.settings.isVideoMuted);
      }
    },
    toggleRemoteAudio(speaker) {
      const { isAudioMuted, isVideoMuted } = speaker.settings;
      if (this.isTypeSpeaker) {
        if (!isAudioMuted) {
          this.$emit("participant-off-audio", decodeURIComponent(speaker.name()));
          this.sendMessage(
            MESSAGE_TYPES.CHANGE_SETTINGS,
            this.makeSettings(!isAudioMuted, isVideoMuted),
            speaker,
          );
        }
      } else {
        this.sendMessage(
          MESSAGE_TYPES.CHANGE_SETTINGS,
          this.makeSettings(!isAudioMuted, isVideoMuted),
          speaker,
        );
      }
    },
    toggleRemoteVideo(speaker) {
      const { isAudioMuted, isVideoMuted } = speaker.settings;
      if (this.isTypeSpeaker) {
        if (!isVideoMuted) {
          this.$emit("participant-off-video", decodeURIComponent(speaker.name()));
          this.sendMessage(
            MESSAGE_TYPES.CHANGE_SETTINGS,
            this.makeSettings(isAudioMuted, !isVideoMuted),
            speaker,
          );
        }
      } else {
        this.sendMessage(
          MESSAGE_TYPES.CHANGE_SETTINGS,
          this.makeSettings(isAudioMuted, !isVideoMuted),
          speaker,
        );
      }
    },
    setSettings(settings) {
      const { isAudioMuted, isVideoMuted } = this.localSpeaker.settings;
      if (isAudioMuted !== settings.isAudioMuted) this.toggleAudio();
      if (isVideoMuted !== settings.isVideoMuted) this.toggleVideo();
    },
    sendMySettings(participant = null) {
      this.sendMessage(MESSAGE_TYPES.SETTINGS, { ...this.localSpeaker.settings }, participant);
    },
    sendMessage(type, message, participant = null) {
      const mes = encodeURI(JSON.stringify({ type, message }));
      if (participant) {
        participant.sendMessage(mes);
      } else {
        this.allParticipant.forEach(p => {
          p.sendMessage(mes);
        });
      }
    },
    onMessage(message) {
      const res = JSON.parse(decodeURI(message.text));
      switch (res.type) {
        case MESSAGE_TYPES.SETTINGS: {
          message.from.settings = res.message;
          this.speakers = [...this.speakers];
          break;
        }
        case MESSAGE_TYPES.CHANGE_SETTINGS: {
          this.setSettings(res.message);
          break;
        }
        case MESSAGE_TYPES.CHANGE_TALKING_SPEAKER: {
          this.changeTalkingSpeakerMessageHandler(res.message);
          break;
        }
        case MESSAGE_TYPES.HIGHLIGHT_TALKING: {
          this.highlightTalkingMessageHandler(res.message);
          break;
        }
        default: {
          console.warn("unknown message type");
        }
      }
    },
    detectSpeech(speaker, stream) {
      setTimeout(() => {
        const processor = this.connectProcessorToSource(stream);
        if (!processor) return;
        this.setSpeechInterval(processor, speaker);
      }, OBSERVE_SPEAKER_DELAY);
    },
    connectProcessorToSource(stream) {
      const el = document.getElementById(stream.id());
      if (!el) return null;
      const mediaStream = el.srcObject;
      const source = this.audioContext.createMediaStreamSource(mediaStream);
      const processor = this.audioContext.createScriptProcessor(512);
      processor.onaudioprocess = function(event) {
        const buf = event.inputBuffer.getChannelData(0);
        const bufLength = buf.length;
        let x;
        for (let i = 0; i < bufLength; i++) {
          x = buf[i];
          if (Math.abs(x) >= this.threshold) {
            this.clipping = true;
            this.lastClip = window.performance.now();
          }
        }
      };
      processor.connect(this.audioContext.destination);
      processor.clipping = false;
      processor.lastClip = 0;
      processor.threshold = THRESH_HOLD_LEVEL;
      processor.latency = 750;
      processor.isSpeech = function() {
        if (!this.clipping) return false;
        if (this.lastClip + this.latency < performance.now()) {
          this.clipping = false;
        }
        return this.clipping;
      };
      source.connect(processor);
      return processor;
    },
    setSpeechInterval(processor, speaker) {
      const speakerName = speaker.name();
      const player = this.$refs[speakerName][0].style;
      speaker.intervalId = setInterval(() => {
        const isSpeech = processor.isSpeech();
        this.sendMessage(MESSAGE_TYPES.HIGHLIGHT_TALKING, {
          name: speakerName,
          highlight: isSpeech,
        });
        if (isSpeech) {
          if (this.inFullscreenMode) {
            player.boxShadow = BOX_SHADOW;
          }
          if (this.screenShareRun) return;
          this.throttledChangeTalkingSpeaker(speaker);
        } else {
          player.boxShadow = "none";
        }
      }, UPDATE_SPEECH_INTERVAL);
    },
    // TODO Вынести в миксин
    stopVideoTracks(video) {
      if (!video?.srcObject) return;
      video.srcObject.getTracks().forEach(track => {
        track.stop();
        video.srcObject.removeTrack(track);
      });
    },
    stop() {
      this.stopStream();
      if (this.session && this.streamRoom) {
        this.streamRoom.leave();
      }
      if (this.session && !this.stream) {
        this.session.disconnect();
        this.session = null;
      }
      this.clearSpeakers();
      this.onStopped();
    },
    makeSpeaker(name = null, participant = { name: () => name }) {
      if (!participant.settings) {
        participant.settings = this.makeSettings();
      }
      participant.data = {
        volumeMuted: false,
        videoEl: null,
      };
      return { ...participant, intervalId: null };
    },
    clearSpeakers() {
      this.speakers.forEach(speaker => {
        if (speaker) {
          clearInterval(speaker.intervalId);
        }
      });
      this.speakers = [];
    },
    fullscreen() {
      if (this.onlyFullScreen && this.inFullscreenMode) return;

      if (this.inFullscreenMode) {
        this.$fullscreen.exit("broadcast");
      } else {
        this.$fullscreen.enter("broadcast");
      }
    },
    goOnAir() {
      this.$emit("go-on-air");
    },
    playVideoElement() {
      this.speakers.forEach(s => s.data.videoEl.play());
    },
    addListeners() {
      this.$refs.talkingSpeakerVideo?.addEventListener("playing", this.pendingEnded);
      this.$refs.talkingSpeakerVideo?.addEventListener("pause", this.pendingEnded);
    },
    removeListeners() {
      this.$refs.talkingSpeakerVideo?.removeEventListener("playing", this.pendingEnded);
      this.$refs.talkingSpeakerVideo?.addEventListener("pause", this.pendingEnded);
    },
    pendingEnded() {
      this.pending = false;
      this.$emit("loaded");
    },
    toggleSidePanel() {
      this.sideExpanded = !this.sideExpanded;
    },
    setVideoListeners(participant, video) {
      participant.data.videoEl = video;
      participant.data.volumeMuted = video.muted;
      video.addEventListener("volumechange", () => {
        participant.data.volumeMuted = video.muted;
        this.speakers = [...this.speakers];
      });
      this.setAdditionalAttrsToVideo(video); // Для apple обязательно
      video.play();
    },
    setAdditionalAttrsToVideo(video) {
      video.setAttribute("playsinline", "playsinline");
      video.setAttribute("autoplay", "autoplay");
      video.setAttribute("disablePictureInPicture", "disablePictureInPicture");
    },
    makeSettings(
      isAudioMuted = false,
      isVideoMuted = false,
      avatar = this.userAvatar,
      name = this.userName,
    ) {
      return { isAudioMuted, isVideoMuted, avatar, name };
    },
    showNotification(type, status) {
      if (this.isTypeViewer) return;

      const notification = {
        group: "broadcast",
        type,
        title: "",
        text: "",
        duration: 5000,
      };
      if (status === this.STREAM_STATUS.PUBLISHING && this.stream) {
        notification.title = this.$t("broadcast.streamConnectedTitle");
        notification.text = this.onAirAsLeading
          ? this.$t("broadcast.youOnAir")
          : this.$t("broadcast.streamConnectedText");
      }

      if (status === this.SESSION_STATUS.FAILED) {
        if (this.stream) {
          notification.title = this.$t("broadcast.errors.streamErrorTitle");
          notification.text = this.$t("broadcast.errors.streamErrorText");
          notification.duration = 10000;
        } else {
          notification.title = this.$t("broadcast.errors.error");
          notification.text = this.$t("broadcast.errors.connectionFailed");
        }
      }

      if (status === CAPTURE_STREAM_STATUSES.PUBLISHED) {
        notification.title = this.$t("broadcast.streamCapturePublishedTitle");
      }
      if (status === CAPTURE_STREAM_STATUSES.STOPPED) {
        notification.title = this.$t("broadcast.streamCaptureStoppedTitle");
      }
      if (status === CAPTURE_STREAM_STATUSES.FAILED) {
        notification.title = this.$t("broadcast.streamCaptureFailedTitle");
      }

      if (notification.title || notification.text) {
        this.$notify(notification);
      }
    },
    handleScreenShare() {
      this.streamCapture ? this.stopCaptureStream() : this.startCaptureStream();
    },
    startCaptureStream() {
      const display = this.$refs.screenShare;
      this.streamRoom
        .publish({
          name: CAPTURE_STREAM_POSTFIX,
          display,
          cacheLocalResources: false,
          disableConstraintsNormalization: this.isSafariWebRTCIOS,
          constraints: this.streamCaptureConstraints,
        })
        .on(this.STREAM_STATUS.STOPPED, () => {
          this.showNotification("success", CAPTURE_STREAM_STATUSES.STOPPED);
          this.handleStoppedCaptureStream();
        })
        .on(this.STREAM_STATUS.PUBLISHING, stream => {
          this.log(`CAPTURE STREAM_PUBLISHING: ${stream.name()}`, this.$sentryLoggerLevels.Info);
          this.streamCapture = stream;
          this.showNotification("success", CAPTURE_STREAM_STATUSES.PUBLISHED);
          /*
           * User can stop sharing screen capture using Chrome "stop" button.
           * Catch onended video track event and stop publishing.
           */
          stream.tracks = document.getElementById(stream.id()).srcObject.getTracks();
          stream.tracks.forEach(track => {
            track.addEventListener("ended", this.stopCaptureStream);
          });
        })
        .on(this.STREAM_STATUS.FAILED, stream => {
          this.log(new Error("CAPTURE STREAM_FAILED"), {
            extra: {
              info: stream.getInfo(),
            },
            level: this.$sentryLoggerLevels.Critical,
          });
          if (!this.destroying) {
            this.showNotification("error", CAPTURE_STREAM_STATUSES.FAILED);
            this.handleStoppedCaptureStream();
          }
        })
        .on(this.STREAM_STATUS.UNPUBLISHED, () => {
          if (!this.destroying) {
            this.showNotification("success", CAPTURE_STREAM_STATUSES.STOPPED);
          }
          this.handleStoppedCaptureStream();
        });
    },
    stopCaptureStream() {
      if (!this.streamCapture) return;
      this.streamCapture.tracks?.forEach(track => {
        track.removeEventListener("ended", this.stopCaptureStream);
        track.stop();
      });
      this.streamCapture.stop();
    },
    handleStoppedCaptureStream() {
      this.streamCapture = null;
      if (this.$refs.screenShare) {
        this.$refs.screenShare.innerHTML = "";
      }
    },
    handlePlayerMouseEnter() {
      this.$refs.playerControls?.startShowButtons();
    },
    handlePlayerMouseMove() {
      this.$refs.playerControls?.throttledShowButtons();
    },
    handlePlayerMouseLeave() {
      this.$refs.playerControls?.stopShowButtons();
    },
    changeVolume(value) {
      this.otherSpeakers.forEach(speaker => {
        speaker.data.videoEl.muted = value === 0;
        speaker.data.videoEl.volume = value / 100;
      });
      this.volume = value;
    },
    changeScreenShareVolume() {
      if (this.participantStreamCapture) {
        const video = document.getElementById(this.participantStreamCapture.id());
        if (video) {
          video.muted = !this.volumeMuted;
          video.volume = this.volume / 100;
        }
      }
    },
    resumeAudioCtx() {
      if (this.audioContext.state === "suspended") {
        this.audioContext.resume();
      }
    },
    changeTalkingSpeakerHandler(speaker) {
      this.sendMessage(MESSAGE_TYPES.CHANGE_TALKING_SPEAKER, speaker.name());
    },
    changeTalkingSpeakerMessageHandler(name) {
      if (this.isTypeSpeaker) return;
      const candidate = this.speakers.find(s => s.name() === name);
      if (candidate && candidate.name() !== this.localSpeaker.name() && !this.screenShareRun) {
        this.talkingSpeaker = candidate;
      }
    },
    highlightTalkingMessageHandler({ name, highlight }) {
      if (this.isTypeSpeaker || !this.$refs[name]) return;
      const player = this.$refs[name][0];
      if (player && name !== this.localSpeaker.name()) {
        if (highlight && this.inFullscreenMode) {
          player.style.boxShadow = BOX_SHADOW;
        } else {
          player.style.boxShadow = "none";
        }
      }
    },
  },
};
</script>

<style lang="scss" module>
$aspect-ratio: calc(9 / 16 * 100%);

/* stylelint-disable no-descending-specificity, selector-max-specificity */
.player {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  max-width: 100vw;
  height: 100%;
  max-height: 100vh;

  &__container {
    position: relative;
    display: grid;
    grid-template-columns: 1fr max-content;
    width: 100vw;
    height: 100%;
    overflow: hidden;
    background: #222;
  }

  &__content {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__sidePanel {
    display: grid;
    grid-template-rows: max-content 1fr;
    width: 0;
    height: 100vh;
    background: #111;
    border-radius: 10px 0 0 10px;
    transition: width 300ms;

    &_expanded {
      width: 30vw;

      @media (min-width: 1024px) {
        width: 320px;
      }
    }
  }

  &__title {
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 130px;
    padding: 30px 20px 0;
    color: #fff;

    @media (min-width: 1024px) {
      width: 320px;
      font-size: 17px;
    }
  }

  &__speakers {
    padding: 0 20px;
    overflow-y: scroll;
  }

  &__counter {
    color: #999;
  }

  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.speakerPlayer {
  position: relative;
  width: calc(30vw - 40px);
  height: 0;
  padding-top: calc(9 / 16 * (30vw - 40px));
  margin-top: 15px;
  overflow: hidden;
  background: #555;
  border-radius: 5px;

  @media (min-width: 1024px) {
    width: 280px;
    padding-top: calc(9 / 16 * 280px);
  }

  &__sideControls {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.3s, opacity 0.3s;

    .player__sidePanel:hover & {
      visibility: visible;
      opacity: 1;
    }
  }

  &_main {
    display: none;
  }

  &_speak {
    width: 100%;
    padding-top: calc(9 / 16 * 100%);
    margin: 0;
  }

  &__inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    & video {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: auto;
      transform: translate(-50%, -50%);
    }
  }
}

.player_mini {
  position: relative;
  z-index: 0;
  width: 100%;
  height: 100%;
  padding-top: $aspect-ratio;

  .player__container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    grid-gap: 0;
    align-content: normal;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0;
  }

  .player__sidePanel {
    display: none;
  }

  .speakerPlayer {
    display: none;

    &__inner {
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      transform: translate(-50%, -50%);
    }

    &_speak {
      position: relative;
      top: initial;
      left: initial;
      display: block;
      width: 100%;
      padding-top: $aspect-ratio;
      border-radius: 0;
      transform: initial;
    }

    &_main {
      position: absolute;
      top: unset;
      right: 2%;
      bottom: 2%;
      left: unset;
      z-index: 4;
      display: block;
      width: 100px;
      padding-top: calc(9 / 16 * 100px);
      font-size: 8px;
      background-color: #444;
      transform: translate(0, 0);
    }
  }
}

@media (orientation: portrait) {
  .player {
    &__container {
      grid-template-rows: 1fr max-content;
      grid-template-columns: 1fr;
    }

    &__sidePanel {
      display: grid;
      grid-template-rows: max-content 1fr;
      width: 100%;
      height: 0;
      transition: height 300ms;

      &_expanded {
        height: 25vh;
      }
    }

    &__title {
      padding-top: 10px;
    }

    &__speakers {
      display: grid;
      grid-template-columns: repeat(100, 45vw);
      grid-gap: 10px;
      align-items: center;
      width: 100vw;
      overflow-x: scroll;
    }
  }

  .speakerPlayer {
    width: 100%;
    padding-top: $aspect-ratio;
    margin-top: 0;

    &_speak {
      top: 0;
      right: unset;
      bottom: unset;
      left: 50vw;
      transform: translate(-50%, 0);
    }
  }
}
</style>
