<template>
  <div class="player-inner-container">
    <yt-player v-if="youtube" :url="url" autoplay />
    <vimeo-player v-else-if="vimeo" :url="url" autoplay />
    <video-player
      v-else-if="typical"
      :provided-url="url"
      autoplay
      :multilingual="multilingual"
      v-on="$listeners"
    />
    <hls-player v-else-if="mp4" :provided-url="url" autoplay v-on="$listeners" />
    <facecast-player v-else-if="facecast" :url="url" />

    <transition name="fade">
      <broadcast-screen-saver
        v-if="displayScreenSaver"
        :image-src="screenSaverSrc"
        :disable-timer="isViewer"
        @screen-saver-hide="$emit('screen-saver-hide')"
      />
    </transition>
  </div>
</template>

<script>
import VideoPlayer from "@/components/event/room/VideoPlayer";
import HlsPlayer from "@/components/event/room/HlsPlayer";
import FacecastPlayer from "@/components/event/room/FacecastPlayer";
import YtPlayer from "@/components/event/room/YtPlayer";
import VimeoPlayer from "@/components/event/room/VimeoPlayer";
import BroadcastScreenSaver from "@/components/common/broadcast/BroadcastScreenSaver";
import { STREAM_TYPES } from "@/constants/broadcast/broadcast-const";

export default {
  name: "BroadcastExternalPlayers",
  components: {
    YtPlayer,
    VimeoPlayer,
    VideoPlayer,
    HlsPlayer,
    FacecastPlayer,
    BroadcastScreenSaver,
  },
  props: {
    youtube: {
      type: Boolean,
      default: false,
    },
    vimeo: {
      type: Boolean,
      default: false,
    },
    mp4: {
      type: Boolean,
      default: false,
    },
    typical: {
      type: Boolean,
      default: false,
    },
    facecast: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      required: true,
      default: "",
    },
    multilingual: {
      type: Boolean,
      default: false,
    },
    screenSaverSrc: {
      type: String,
      default: "",
    },
    displayScreenSaver: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isViewer() {
      return this.type === STREAM_TYPES.VIEWER;
    },
  },
};
</script>

<style lang="scss">
.player-inner-container {
  width: 100%;
  height: 100%;
}
</style>
